<template>
  <base-card
    name="expiredScans"
    @removeCard="$emit('removeCard')"
  >
    <div class="card-main">
      <div class="sub-header">
        {{ $t('allEntities') }}
      </div>
      <sortable-grid
        v-if="filteredScans.length > 0"
        :loading="isloading"
        :columns="columns"
        :items="filteredScans"
        :modules="modules"
        :page-size="6"
        :hide-shadow="true"
        :show-header-border="true"
        :has-top-border="false"
        :has-bottom-border="false"
        :show-header="filteredScans.length > 0"
        :grid-container-padding="false"
      />
      <div
        v-else-if="!isloading && filteredScans.length === 0"
        class="no-data"
      >
        <h2>{{ $t('noDataAvailable') }}</h2>
      </div>
    </div>
  </base-card>
</template>
<script>
import BaseCard from './BaseCard'

import SortableGrid from '../../../../brandcheck/components/Grids/SortableGrid'
import StringItem from '../../../../brandcheck/components/Modules/StringItem'

import * as scanApi from '../../../../brandcheck/API/ScanApi'
import * as mapper from '../../../../brandcheck/mappers/ScanMapper'

import _ from 'lodash'

export default {
  components: {
    SortableGrid,
    BaseCard
  },

  data () {
    return {
      selectedScanId: '',
      showArchived: false,
      showEdit: false,
      showModal: false,
      scanSummaries: [],
      columns: this.generateColumnSet(),
      modules: { StringItem },
      isloading: false,
      searchValue: ''
    }
  },

  computed: {
    archivedScansButton () {
      return this.showArchived ? 'hideArchivedScans' : 'showArchivedScans'
    },
    filteredScans () {
      if (this.searchValue) {
        const searchValue = this.searchValue.toLowerCase()
        return _.values(_.filter(this.scanSummaries, (scan) => {
          return (scan.locationName.toLowerCase().includes(searchValue) ||
            scan.entityName.toLowerCase().includes(searchValue) ||
            scan.scanTypeName.toLowerCase().includes(searchValue) ||
            scan.userNameScan.toLowerCase().includes(searchValue) ||
            this.$t(`stateScan.${scan.stateScan}`).toLowerCase().includes(searchValue) ||
            scan.scanDate.includes(searchValue))
        }))
      } else {
        return this.scanSummaries
      }
    }
  },

  created () {
    this.$on('itemClick', function (id) { this.handleClick(id) })
    this.$on('canceled', function (id) { this.showModal = false; this.selectedScanId = '' })
    this.$on('submitted', function () { this.handleSubmitted() })
    this.getScanData()
    this.isloading = false
  },

  methods: {
    getScanData () {
      const self = this
      self.isloading = true
      scanApi.getAllScanTypes().then(result => {
        self.scanTypes = result
      })
      scanApi.getAllScans('', this.showArchived).then(result => {
        // Loops through all scans and creates a localized property value for EntityTypeName and ScanTypeName
        result.forEach(resultItem => {
          resultItem.localizedEntityTypeName = this.$t(_.lowerFirst(resultItem.EntityTypeName))
          resultItem.localizedScanTypeName = this.$t(resultItem.ScanTypeName)
        })
        self.scanSummaries = mapper.toScanSummaries(_.filter(result, function (scan) { return scan.State === 3 }))
        self.isloading = false
      })
    },
    generateColumnSet () {
      return [
        { name: this.$t('entity'), type: 'StringItem', key: 'entityName', associatedId: 'scanId' },
        { name: this.$t('taskManagementPage.assignedTo'), type: 'StringItem', key: 'userNameScan', associatedId: 'scanId' },
        { name: this.$t('deadline'), type: 'StringItem', key: 'scanDate', associatedId: 'scanId', formatType: 'date' },
        { name: this.$t('status'), type: 'StringItem', key: 'stateScan', formatType: 'scanState', associatedId: 'scanId' }
      ]
    },
    handleClick (id) {
      const selectedScan = this.scanSummaries.find(s => s.scanId === id)

      // 2 = status completed
      if (selectedScan.stateScan !== 1) {
        this.$router.push({ name: 'scanDetail', params: { scanId: id } })
      } else {
        this.showModal = true
        this.selectedScanId = id
      }
    },
    handleSubmitted () {
      this.showModal = false
      this.selectedScanId = ''
      this.getScanData()
    },
    handleSearch (searchValue) {
      this.searchValue = searchValue
    },
    toggleArchivedScans (showArchived) {
      this.showArchived = showArchived
      this.getScanData()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/style/style.scss';
@import '@/common/style/DashboardCard.scss';

.upper {
  height: rem(50);

  .entity-select {
    display: inline-block;

    &-container {
      margin-top: rem(13);
      display: inline-block;

      .total {
        margin-left: rem(50);
        display: inline-block;

        &-passed { color: $successful; }

        &-failed { color: $unsuccessful; }
      }
    }
  }
}

.lower { height: inherit; }
</style>
