<template>
  <div
    :class="{
      'input-container': true,
      'small': small,
      'disabled': disabled,
      'active': active,
      'valid': valid && !empty,
      'invalid': !valid && !active && touched
    }"
  >
    <div
      v-if="expandOptions"
      ref="select"
      class="drop-down-window"
      tabindex="0"
      :style="windowStyle"
      @focus="focus()"
      @blur="hideOptions()"
    >
      <div
        v-if="allowDeselect"
        :style="dropDownWidth"
        class="drop-down-options-container"
        @click="select(null)"
      >
        <span
          class="drop-down-option italic-14"
        >
          {{ placeHolder }}
        </span>
      </div>
      <div
        v-for="(option, key) in options"
        :key="key"
        :style="dropDownWidth"
        class="drop-down-options-container"
        @click="select(option)"
      >
        <span
          class="drop-down-option"
        >
          {{ key }}
        </span>
      </div>
    </div>
    <input
      class="drop-down"
      :value="selectedKey"
      :placeholder="placeHolder"
      :options="options"

      :style="inputMargins"

      :disabled="disabled"
      readonly

      :tabindex="tabIndex"
      @click="showOptions()"
    >
    <div
      class="afterIcon"
      @click="showOptions()"
    >
      <img src="/asset/icons/dropdown.svg">
    </div>
  </div>
</template>

<script>
import { find } from 'lodash'

export default {
  props: {
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: ''
    },

    options: {
      type: Object,
      required: true
    },

    allowDeselect: {
      type: Boolean,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    },

    placeHolder: {
      type: String,
      default: ''
    },

    valid: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    tabIndex: {
      type: Number,
      default: 0
    },

    width: {
      type: Number,
      default: null
    },

    maxHeight: {
      type: Number,
      default: 264
    }
  },

  data () {
    return {
      active: false,
      touched: false,
      expandOptions: false
    }
  },

  computed: {
    empty () {
      return this.value === ''
    },

    afterIcon () {
      return typeof this.$slots.after !== 'undefined'
    },

    inputMargins () {
      return {
        'margin-left': this.beforeIcon ? '0px' : '10px',
        'margin-right': this.afterIcon ? '0px' : '10px'
      }
    },

    selectedKey () {
      const watchedValue = this.value
      const selectedOption = find(Object.entries(this.options), option =>
        option[1] === watchedValue
      )
      return selectedOption ? selectedOption[0] : ''
    },

    dropDownWidth () {
      return { width: this.width + 'px' }
    },

    windowStyle () {
      return { 'max-height': this.maxHeight + 'px' }
    }
  },

  methods: {
    select (event) {
      this.touched = true
      this.$emit('input', event)
      this.expandOptions = false
    },

    showOptions () {
      this.expandOptions = true
      this.$nextTick(function () {
        const ref = this.$refs
        ref.select.focus()
      })
    },

    hideOptions () {
      this.expandOptions = false
    },

    focus () {
      this.active = true
    },

    blur () {
      this.expandOptions = false
      this.active = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.input-container {
  height: 48px;
  display: flex;
  cursor: text;
  font-weight: 400;
  font-size: 14px;
  position: relative;
  color: $defaultFontColor;
  border: 1px solid $defaultBorderColor;

  input {
    width: calc(100% - 38px);
    height: 100%;
    flex-grow: 1;
    border: none;
    outline: none;
    cursor: inherit;
    background: none;
  }

  .drop-down {

    &-window {
      width: 100%;
      z-index: 100;
      outline: none;
      left: -1px;
      position: absolute;
      overflow: auto;
      background: $white;
      margin-top: 55px;
      line-height: 50px;
      border: 1px solid $defaultBorderColor;
    }

    &-options-container {
      cursor: pointer;

      &:hover { background-color: $defaultBorderColor; }
    }

    &-option {
      display: block;
      margin: 0 10px;

      &.italic-14 {
        color: $infoGray;
      }
    }
  }

  .afterIcon {
    height: 100%;
    display: flex;
    flex-flow: column;
    max-width: 14px;
    line-height: 25px;
    margin-right: 10px;
    justify-content: center;

    img {
      max-height: 14px;
      vertical-align: center;
    }
  }

  &:placeholder { color: $infoGray; }

  &.small {
    height: 23px;

    .drop-down {

      &-window {
        margin-top: 30px;
        line-height: 25px;
      }
    }
  }

  &.invalid {
    color: $errorRed;
    border: 1px solid $goalRed;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $white;
    color: $defaultBorderColor;
    border: 1px solid $disabledBorderColor;
  }
}
</style>
