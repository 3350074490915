import { postJson, deleteJson, getJson, putJson } from './BaseApi.js'

const baseRoute = '/api/company'

export const getAllCompanies = function () {
  return getJson({ url: `${baseRoute}/all` })
}

export const createCompany = function (name, domain) {
  return postJson({
    url: `${baseRoute}`,
    data: {
      Name: name,
      Domain: domain
    }
  })
}

export const deleteCompany = function (companyId) {
  return deleteJson({ url: `${baseRoute}/${companyId}` })
}

export const updateCompany = function (companyId, name, domain) {
  return putJson({
    url: `${baseRoute}/${companyId}`,
    data: {
      Id: companyId,
      Name: name,
      Domain: domain
    }
  })
}
