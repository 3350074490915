export const toScanSummaries = function (items) {
  return items.map(item => ({
    scanId: item.Id,
    scanTypeName: item.localizedScanTypeName,
    locationName: item.LocationName,
    entityName: item.EntityName + ' (' + item.localizedEntityTypeName + ')',
    typeScan: item.ScanType,
    userNameScan: item.ScanUser,
    stateScan: item.State,
    scanDate: item.ScanDate
  }))
}
