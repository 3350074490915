import * as analysisApi from '@/brandcheck/API/AnalysisApi'

export const actionTypes = {
  GET_BUILDINGPROGRESS: 'BUILDING/GET_BUILDINGPROGRESS',
  GET_BUILDINGPROGRESSDETAILS: 'BUILDING/GET_BUILDINGPROGRESSDETAILS'
}

export const mutationTypes = {
  SET_BUILDINGPROGRESS: 'BUILDING/SET_BUILDINGPROGRESS',
  SET_BUILDINGPROGRESSDETAILS: 'BUILDING/SET_BUILDINGPROGRESSDETAILS',
  CLEAR_STORE: 'BUILDING/CLEAR_STORE'
}

const state = {
  buildingProgressItems: null,
  buildingProgressDetails: null
}

const getters = {
  buildingProgressItems: (state) => state.buildingProgressItems,
  buildingProgressDetails: (state) => state.buildingProgressDetails
}

const actions = {
  [actionTypes.GET_BUILDINGPROGRESS] ({ commit }, searchValue) {
    return analysisApi.getEntityProgressItems(searchValue)
      .then(result => {
        commit(mutationTypes.SET_BUILDINGPROGRESS, result)
      })
  },

  [actionTypes.GET_BUILDINGPROGRESSDETAILS] ({ commit }, entityId) {
    return analysisApi.getAggregatedEntityProgressDetails(entityId)
      .then(result => {
        commit(mutationTypes.SET_BUILDINGPROGRESSDETAILS, result)
        return result
      })
  }
}

const mutations = {
  [mutationTypes.SET_BUILDINGPROGRESS] (state, result) {
    state.buildingProgressItems = result
  },

  [mutationTypes.SET_BUILDINGPROGRESSDETAILS] (state, result) {
    state.buildingProgressDetails = result
  },

  [mutationTypes.CLEAR_STORE] (state) {
    state.buildingProgressItems = null
  }
}

export const storeModule = {
  state,
  getters,
  actions,
  mutations
}
