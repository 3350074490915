import { getJson, postJson, putJson, deleteJson, postFile } from '../../common/API/BaseApi.js'
import { getBrandcheckApiEndpoint } from '@/common/proxyConfig'

export function uploadMedia ({ taskId, media }) {
  return postFile({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/${taskId}/upload`, file: media })
}

const baseRoute = 'api/taskmanagement'

export const getTreeLocations = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/tree-locations` })
}

export const getTreeDescendants = (parentId, readAll) => {
  if (readAll) {
    return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/tree-entities/descendants/${parentId}` })
  } else return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/assigned-tree-entities/descendants/${parentId}` })
}

export const getAllCategoryProgress = function (goalId, readAll) {
  if (readAll) {
    return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/category-progress/${goalId}` })
  } else return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/assigned-category-progress/${goalId}` })
}

export const getAllTasks = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/tasks` })
}

export const getTasksByScanId = function (scanId, readAll) {
  if (readAll) {
    return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/tasks/${scanId}` })
  } else return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/assigned-tasks/${scanId}` })
}

export const getCategoryTasks = function (scanId, categoryId, readAll) {
  if (readAll) {
    return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/tasks/${scanId}/${categoryId}` })
  } else return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/assigned-tasks/${scanId}/${categoryId}` })
}

export const getStatuses = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/statuses` })
}

export const createDefectTask = function (defectTask) {
  return postJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}`, data: defectTask })
}

export const updateDefectTask = function (defectTask) {
  return putJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}`, data: defectTask })
}

export const deleteDefectTask = function (defectTask) {
  return deleteJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}`, data: defectTask })
}

export const getTaskMediaByTaskId = function ({ taskId }) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/${taskId}/media` })
}

export const getTaskMediaLink = function ({ taskMediaId }) {
  return `${getBrandcheckApiEndpoint()}/${baseRoute}/media/${taskMediaId}`
}

export const deleteTaskMedia = function ({ taskMediaId }) {
  return deleteJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/media/${taskMediaId}` })
}

export const getDefectHistory = answerId =>
  getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/task-history/${answerId}` })

export const getTask = (answerId, categoryId) =>
  getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/${answerId}/${categoryId}` })
