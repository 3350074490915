import { storeModule as sessionModule } from './sessionStore'
import { storeModule as userModule } from './userModule'
import { storeModule as companyModule } from './companyModule'
import { storeModule as drawingModule } from './drawingModule'
import { storeModule as entityModule } from './entityModule'
import { storeModule as locationModule } from './locationModule'

export default {
  session: sessionModule,
  users: userModule,
  companies: companyModule,
  drawings: drawingModule,
  entities: entityModule,
  locations: locationModule
}
