<template>
  <div id="app">
    <p4a-header
      :product="'tbq'"
      :customer="applicationName"
      :logged-in="showLogout"
      :buttons="rightButtons"
      :style="{
        position: 'fixed',
        zIndex: '3'
      }"
    />
    <div
      id="container"
      :class="{ 'smallNavigation': navigationCollapsed, 'authenticated': isAuthenticated }"
    >
      <navigation
        v-if="isAuthenticated"
        :collapsed="navigationCollapsed"
      />
      <router-view id="application" />
    </div>
    <div
      v-if="!isAuthenticated"
      class="background"
    />
    <p4a-toast
      :max-width="370"
      :duration="10000"
    />
  </div>
</template>

<script>
import Navigation from '@/wrapper/Navigation'

import BrandCheckModule from '@/brandcheck/store/store'
import { actionTypes as userActions } from '@/common/store/userModule'

import { actionTypes as sessionActionTypes } from '@/common/store/sessionStore'

export default {
  components: {
    Navigation
  },

  data () {
    return {
      rightButtons: [
        {
          icon: 'logout',
          function: this.logout
        }
      ]
    }
  },

  computed: {
    applicationName () {
      if (this.$route.matched.length === 0) {
        return ''
      } else {
        switch (this.$route.matched[0].meta.application) {
          case 'brandcheck':
            return 'Brandcheck'
          case 'ontruimcheck':
            return 'Ontruimcheck'
          default:
            return ''
        }
      }
    },
    showLogout () {
      return this.$store.getters.isAuthenticated
    },
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    navigationCollapsed () {
      return this.$store.getters.settings.NavigationCollapsed || false
    }
  },

  created () {
    this.$store.registerModule('brandCheck', BrandCheckModule)
    this.$store.dispatch(userActions.GET_USER_SETTINGS)
  },

  methods: {
    validate (value) {
      return value !== null && typeof value !== 'undefined'
    },
    logout () {
      this.$store.dispatch(sessionActionTypes.LOGOUT).then(
        this.$router.push({ name: 'login' })
      )
    }
  }
}
</script>

<style lang="scss">
@import '@/common/style/style.scss';
@import '../common/Fonts/index.css';

html, body, ul {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

* {
  outline: none;
  box-sizing: border-box;
}

.custom-container { padding: 25px 50px; }
</style>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.background {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom rem(50) right 0;
  background-image: url('../../public/asset/wrapper/login/background.svg');
}

#app {
  overflow: hidden;
  color: $titelColor;
  font-family: 'Roboto', sans-serif;

  #container {
    display: flex;
    padding-top: 50px;

    #application { transition-duration: 0.3s; }

    &.authenticated {
      #application {
        width: calc(100% - 300px);

        margin-left: 300px;
      }

      &.smallNavigation {
        #application {
          width: calc(100% - 50px);

          margin-left: 50px;
        }
      }
    }
  }
}
</style>
