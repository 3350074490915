import * as userApi from '@/common/API/UserApi.js'
import * as accountApi from '@/common/API/AccountApi.js'
import { unionBy, remove } from 'lodash'
import Vue from 'vue'

export const actionTypes = {
  GET_ALL_USERS: 'USERS/GET_ALL_USERS',
  DELETE_USER: 'USERS/DELETE_USER',
  GET_USER_SETTINGS: 'USER/GET_SETTINGS',
  UPDATE_NAVIGATION_STYLE: 'USER/UPDATE_NAVIGATION_STYLE'
}

export const mutationTypes = {
  SET_USERS: 'USERS/SET_USERS',
  DELETE_USER: 'USERS/DELETE_USER',
  SET_USER_SETTINGS: 'USER/SET_SETTINGS',
  UPDATE_NAVIGATION_STYLE: 'USER/UPDATE_NAVIGATION_STYLE'
}

const state = {
  users: [],
  settings: {}
}

const getters = {
  users: (state) => state.users,
  settings: (state) => state.settings
}

const actions = {
  [actionTypes.GET_ALL_USERS] ({ commit }) {
    return userApi.getAllUsers('').then(results => {
      commit(mutationTypes.SET_USERS, results)
    })
  },
  [actionTypes.DELETE_USER] ({ commit }, userId) {
    return userApi.deleteUser(userId).then(result => {
      commit(mutationTypes.DELETE_USER, userId)
    })
  },
  [actionTypes.GET_USER_SETTINGS] ({ commit }) {
    return userApi.getAllSettings().then(result => {
      commit(mutationTypes.SET_USER_SETTINGS, result)
    })
  },
  [actionTypes.UPDATE_NAVIGATION_STYLE] ({ commit }, collapsed) {
    return accountApi.saveNavigationConfiguration(collapsed).then(() => {
      commit(mutationTypes.UPDATE_NAVIGATION_STYLE, collapsed)
    })
  }
}

const mutations = {
  [mutationTypes.SET_USERS] (state, results) {
    state.users = unionBy(results, state.users, 'Id')
  },
  [mutationTypes.DELETE_USER] (state, userId) {
    state.users = remove(state.users, ['Id', userId])
  },
  [mutationTypes.SET_USER_SETTINGS] (state, result) {
    state.settings = result
  },
  [mutationTypes.UPDATE_NAVIGATION_STYLE] (state, collapsed) {
    Vue.set(state.settings, 'NavigationCollapsed', collapsed)
  }
}

export const storeModule = {
  state,
  getters,
  actions,
  mutations
}
