import * as companyApi from '@/common/API/CompanyApi'

export const actionTypes = {
  GET_ALL_COMPANIES: 'ORG/GET_ALL_COMPANIES'
}

export const mutationTypes = {
  SET_COMPANIES: 'ORG/SET_COMPANIES'
}

const state = {
  companies: []
}

const getters = {
  companies: (state) => state.companies
}

const actions = {
  [actionTypes.GET_ALL_COMPANIES] ({ commit }) {
    return companyApi.getAllCompanies().then(result => {
      commit(mutationTypes.SET_COMPANIES, result)
    })
  }
}

const mutations = {
  [mutationTypes.SET_COMPANIES] (state, result) {
    state.companies = result
  }
}

export const storeModule = {
  state,
  getters,
  actions,
  mutations
}
