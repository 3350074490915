import * as accountApi from '@/common/API/AccountApi'
import Vue from 'vue'
import { each } from 'lodash'

export const actionTypes = {
  AUTHENTICATE: 'SESSION/AUTHENTICATE',
  VALIDATE: 'SESSION/VALIDATE',
  LOGOUT: 'SESSION/LOGOUT',
  GET_LICENSES: 'ACC/GET_LICENSES',
  GET_PERMISSIONS: 'ACC/GET_PERMISSIONS'
}

export const mutationTypes = {
  LOGIN: 'SESSION/LOGIN',
  CLEAR_STORE: 'SESSION/CLEAR_STORE',
  SET_LICENSES: 'ACC/SET_LICENSES',
  SET_PERMISSIONS: 'ACC/SET_PERMISSIONS'
}

const state = {
  authenticatedUser: null,
  licenses: {},
  permissions: {}
}

const getters = {
  authenticatedUser: state => state.authenticatedUser,
  isAuthenticated: state => state.authenticatedUser !== null,
  licenses: (state) => state.licenses,
  permissions: state => state.permissions
}

const actions = {
  [actionTypes.AUTHENTICATE] ({ commit }, { email, password }) {
    return accountApi.authenticateUser({ email, password })
      .then(authResult => {
        commit(mutationTypes.LOGIN, authResult)
      })
  },
  [actionTypes.VALIDATE] ({ commit }) {
    return accountApi.validateUser()
      .then(authResult => {
        if (authResult === null) {
          commit(mutationTypes.CLEAR_STORE)
        } else {
          commit(mutationTypes.LOGIN, authResult)
        }
      })
  },
  [actionTypes.LOGOUT] ({ commit }) {
    commit(mutationTypes.CLEAR_STORE)
  },
  [actionTypes.GET_LICENSES] ({ commit }) {
    return accountApi.validateLicenses().then(result => {
      commit(mutationTypes.SET_LICENSES, result)
    }).catch(() => {
      commit(mutationTypes.SET_LICENSES, [])
    })
  },
  [actionTypes.GET_PERMISSIONS] ({ commit }) {
    return accountApi.validatePermissions().then(result => {
      commit(mutationTypes.SET_PERMISSIONS, result)
    }).catch(() => {
      commit(mutationTypes.SET_PERMISSIONS, [])
    })
  }
}

const mutations = {
  [mutationTypes.LOGIN] (state, authResult) {
    // Save auth token in local-storage
    localStorage.setItem('auth-token', authResult.Token)

    state.authenticatedUser = authResult.User
  },
  [mutationTypes.CLEAR_STORE] (state) {
    state.authenticatedUser = null
    localStorage.removeItem('auth-token')
  },
  [mutationTypes.SET_LICENSES] (state, result) {
    Vue.set(state, 'licenses', result)
  },
  [mutationTypes.SET_PERMISSIONS] (state, result) {
    each(result, p => {
      Vue.set(state.permissions, p.Name, p.PermissionId)
    })
  }
}

export const storeModule = {
  state,
  getters,
  actions,
  mutations
}
