<template>
  <div
    id="nav"
    :class="{ 'medium-13': true, 'small': collapsed }"
  >
    <div class="expand">
      <div class="content">
        <p4a-button
          class="menu-button"
          :show-icon="true"
          :only-icon-visible="true"
          :icon="'menu-collapse'"
          :icon-color="'#58585B'"
          :disable="false"
          @click="updateNavigationStyle"
        />
      </div>
    </div>
    <router-link
      v-for="menu in visibleMenus"
      :key="menu.to"
      :to="menu.to"
      :class="{ 'item': true, 'collapsible': menu.collapsible, 'active': menu.name === activeMenuName }"
    >
      <div class="content">
        <img
          v-if="menu.name === 'brandcheck' || menu.name === 'ontruimcheck'"
          :src="menu.icon"
          class="icon left"
        >
        <p4a-icon
          v-else
          :color="'#58585B'"
          :icon="menu.icon"
          class="icon left"
        />
        <div class="label">
          {{ $t('menu.' + menu.name) }}
        </div>
        <p4a-icon
          v-if="menu.collapsible"
          :color="'#58585B'"
          :icon="'expand-large'"
          class="icon right plus"
        />
        <p4a-icon
          v-if="menu.collapsible"
          :color="'#58585B'"
          :icon="'collapse-large'"
          class="icon right min"
        />
      </div>

      <template
        v-if="menu.collapsible"
      >
        <div
          v-for="submenu in menu.children"
          :key="submenu.to"
        >
          <router-link
            v-if="checkPermission(submenu.requiredPermission)"
            :to="submenu.to"
            :class="{ 'sub-item': true, 'active': submenu.name === activeMenuName }"
          >
            <div class="content">
              <p4a-icon
                :color="'#58585B'"
                :icon="submenu.icon"
                class="icon left"
              />
              <div class="label">
                {{ $t('menu.' + submenu.name) }}
              </div>
            </div>
          </router-link>
        </div>
      </template>
    </router-link>
  </div>
</template>

<script>
import { actionTypes as sessionActionTypes } from '@/common/store/sessionStore'
import { actionTypes as userActionTypes } from '@/common/store/userModule'
import { each, includes } from 'lodash'
import { permissionsMixin } from '@/common/Mixins/permissions.mixin'

export default {
  mixins: [permissionsMixin],

  props: {
    collapsed: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },

  data () {
    return {
      visibleMenus: [],
      allMenus: [
        {
          to: '/dashboard',
          name: 'dashboard',
          icon: 'dashboard',
          requiredLicense: 'public'
        },
        {
          to: '/entities',
          name: 'entities',
          icon: 'city',
          requiredLicense: 'public',
          requiredPermission: 'buildings.viewpage'
        },
        {
          to: '/users',
          name: 'users',
          icon: 'user',
          requiredLicense: 'public',
          requiredPermission: 'users.viewpage'
        },
        {
          to: '/drawings',
          name: 'drawings',
          icon: 'drawing',
          requiredLicense: 'public',
          requiredPermission: 'drawings.viewpage'
        },
        {
          to: '/brandcheck',
          name: 'brandcheck',
          icon: '/asset/wrapper/brandcheck-icon.svg',
          collapsible: true,
          requiredLicense: '5b7478f4-2749-4bce-8a4e-884a5ead0105',
          children: [
            {
              to: '/brandcheck/overviewscan',
              name: 'overviewscan',
              icon: 'scan',
              requiredPermission: 'scans.viewpage'
            },
            {
              to: '/brandcheck/analysebuilding',
              name: 'analyseBuilding',
              icon: 'status',
              requiredPermission: 'analyses.viewpage'
            },
            {
              to: '/brandcheck/taskmanagement',
              name: 'taskManagement',
              icon: 'adjust',
              requiredPermission: 'tasks.viewpage'
            }
          ]
        },
        {
          to: '/ontruimcheck',
          name: 'ontruimcheck',
          icon: '/asset/wrapper/ontruimcheck-icon.svg',
          collapsible: true,
          requiredLicense: '70a4fecb-589b-47ad-9a09-22f56bd7a42c',
          requiredPermission: 'simulations.viewpage',
          children: [
            {
              to: '/ontruimcheck/wizard/new',
              name: 'wizard',
              icon: 'change',
              requiredPermission: 'simulations.create'
            },
            {
              to: '/ontruimcheck/list',
              name: 'list',
              icon: 'file',
              requiredPermission: 'simulations.read'
            }
          ]
        }
      ]
    }
  },

  computed: {
    activeMenuName () {
      return this.$route.meta && this.$route.meta.relatedMenu ? this.$route.meta.relatedMenu : this.$route.name
    },
    licences () {
      return this.$store.getters.licenses
    }
  },

  watch: {
    collapsed: function () {
      // The Navigation should trigger a resize event, because when collapsed is changed the application size changes. This is similar to the resize event.
      // We timeout for 400 ms seconds because the transition takes 300 ms
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 400)
    }
  },

  created () {
    this.$store.dispatch(sessionActionTypes.GET_LICENSES).then(() =>
      this.$store.dispatch(sessionActionTypes.GET_PERMISSIONS).then(() => this.loadVisibleMenus()))
  },

  methods: {
    loadVisibleMenus () {
      each(this.allMenus, menu => {
        if (this.validateLicense(menu.requiredLicense) && this.checkPermission(menu.requiredPermission)) {
          this.visibleMenus.push(menu)
        }
      })
    },
    validateLicense (requiredLicense) {
      return includes(this.licences, requiredLicense) || requiredLicense === 'public'
    },
    updateNavigationStyle () {
      this.$store.dispatch(userActionTypes.UPDATE_NAVIGATION_STYLE, !this.collapsed)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.menu-button {
  /deep/
  &.btn {
    width: 50px;
    height: 50px;

    .icon {
      max-width: 20px;
      max-height: 20px;
    }
  }
}

.small {
  .menu-button { transform: rotate(180deg); }
}

#nav {
  width: rem(300);
  height: calc(100% - #{rem(50)});
  z-index: 200;
  display: flex;
  position: fixed;
  transition: 0.3s;
  flex-direction: column;
  background-color: $nav-background;

  .expand {

    .content {
      height: rem(50);
      display: flex;
      flex-direction: row-reverse;
      line-height: rem(50);

      .icon {
        width: rem(18);
        height: rem(18);
        cursor: pointer;
        margin: rem(16) rem(16) rem(16) auto;
      }
    }
  }

  .item, .sub-item {
    text-decoration: none;

    .content {
      height: 50px;
      display: flex;
      line-height: 50px;

      .label {
        width: 200px;
        color: $defaultFontColor;
      }

      .icon {
        width: 18px;
        min-width: 18px;
        height: 18px;
        font-size: 17px;
        line-height: 19px;
        margin: 16px 16px;
        color: $defaultFontColor;

        &.min { display: none; }
      }
    }

    .sub-item { display: none; }

    &.collapsible {

      .content {

        .icon {

          &.left {
            width: rem(22);
            height: rem(25);
            min-width: rem(22);
            margin: rem(13) rem(14);
          }
        }
      }

      .sub-item {

        .content {

          .icon {

            &.left {
              width: rem(18);
              min-width: rem(18);
              height: rem(18);
              margin: rem(16) rem(16);
            }
          }
        }
      }
    }

    &:hover { background-color: $darkBorder; }

    &.router-link-active {

      .content {
        background-color: $darkBorder;
        border-left: solid rem(5) $backgroundColor;

        .icon {

          &.plus { display: none; }

          &.min { display: block; }

          &.left { margin-left: rem(11); }
        }
      }

      &.collapsible {

        .content {

          .icon {

            &.left { margin-left: rem(9); }
          }
        }
      }

      .sub-item { display: block; }
    }

    &.active {

      .content {
        background-color: $nav-item-active;
        border-left: solid rem(5) $brandColor;
      }

      .sub-item {

        .content {
          background-color: $darkBorder;
          border-left: solid rem(5) $nav-item-active;
        }
      }
    }
  }

  &.small {
    width: rem(50);

    .expand {

      .content {

        .icon { transform: rotate(180deg); }
      }
    }

    .item, .sub-item {

      .content {

        .label, .icon.right { display: none; }

        &:hover {

          .label {
            height: auto;
            z-index: 10;
            color: $white;
            display: block;
            margin-top: auto;
            font-size: rem(11);
            text-align: center;
            margin-bottom: auto;
            white-space: nowrap;
            margin-left: rem(10);
            padding: 5px rem(10);
            line-height: rem(11);
            border-radius: rem(6);
            background-color: $black;
            box-shadow: 0 rem(3) rem(6) 0 $black;

            &::before {
              content: '';
              position: absolute;
              border-style: solid;
              border-width: rem(5);
              margin-left: rem(-20);
              border-color: transparent $black transparent transparent;
            }
          }
        }
      }
    }
  }
}
</style>
