import { getBrandcheckApiEndpoint } from '@/common/proxyConfig'
import { getJson } from './../../common/API/BaseApi.js'

const baseRoute = 'api/definition'

export const getAmbitionGoals = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/ambitiongoals/all` })
}

export const getDeviationDefinitions = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/deviationdefinition/all` })
}

export const getGoals = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/goals/all` })
}

export const getRiskDefinitions = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/riskdefinition/all` })
}
