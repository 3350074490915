import { getBrandcheckApiEndpoint } from '@/common/proxyConfig'
import { postJson, deleteJson, getJson, putJson } from './../../common/API/BaseApi.js'

const baseRoute = 'api/scan'

export const getMyScans = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/currentuser` })
}

export const getExpiringEntities = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/expiring` })
}

export const getUserTally = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/usertally` })
}

export const getAllScans = function (searchParameter = '', onlyDeleted) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/all?searchParameter=${searchParameter}&onlyDeleted=${onlyDeleted}` })
}

export const getScanById = function (scanId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/${scanId}` })
}

export const getAllScanTypes = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/type/all` })
}

export const deleteScan = function (scanId) {
  return deleteJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/${scanId}` })
}

export const getAvailableScans = function (scanType, allowedFinished) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/${scanType}/${allowedFinished}` })
}

export const getScanInfoForEntity = function (entityId) {
  if (entityId !== 'null') {
    return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/latest/${entityId}` })
  }
}

export const getAvailableRolesForScanTypes = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/available-roles` })
}

export const createScan = function (scanSubmit) {
  return postJson({
    url: `${getBrandcheckApiEndpoint()}/${baseRoute}`,
    data: {
      ScanDate: scanSubmit.scanDate,
      EntityId: scanSubmit.entityId,
      ScanTypeId: scanSubmit.scanTypeId,
      UserId: scanSubmit.userId,
      ParentScanId: scanSubmit.parentScanId,
      CopiedScan: scanSubmit.copiedScan
    }
  })
}

export const updateScan = function (scan) {
  return putJson({
    url: `${getBrandcheckApiEndpoint()}/${baseRoute}`,
    data: {
      ScanDate: scan.scanDate,
      EntityId: scan.entityId,
      ScanTypeId: scan.scanTypeId,
      UserId: scan.userId,
      Id: scan.scanId,
      ParentScanId: scan.parentScanId,
      CopiedScan: scan.copiedScan
    }
  })
}

export const getScanTypesByEntityType = function (entityTypeId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/types/${entityTypeId}` })
}

export const getAvailableEntityToCopyScan = entityId =>
  getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/availableEntities/${entityId}` })
