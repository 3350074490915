<template>
  <div class="card">
    <div class="card__header">
      <div class="card__header__name">
        {{ $t(name) }}
      </div>

      <img
        src="/asset/icons/close.svg"
        class="card__close"
        @click="$emit('removeCard')"
      >
    </div>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.card {

  &__header {
    position: relative;

    &__name {
      width: 100%;
      text-align: center;
      font-size: rem(17);
      padding-top: rem(24);
      color: $defaultFontColor;
    }
  }

  &__close {
    width: rem(16);
    height: rem(16);
    top: 0;
    right: 0;
    cursor: pointer;
    margin: rem(17);
    position: absolute;
  }
}
</style>
