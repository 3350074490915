<template>
  <div
    :tabindex="tabIndex"
    :class="{
      'disabled': disabled,
      'brand': brand,
      'small': small,
      'critical': critical,
      'warn': warn
    }"
    @click="click"
    @keyup.enter="click"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    brand: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    critical: {
      type: Boolean,
      default: false
    },
    warn: {
      type: Boolean,
      default: false
    },
    tabIndex: {
      type: Number,
      default: 0
    }
  },

  methods: {
    click (event) {
      if (!this.disabled) {
        this.$emit('click', event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

div {
  min-height: 48px;
  min-width: 108px;

  width: fit-content;

  padding: 0px 20px 0px 20px;

  border: 1px solid $defaultBorderColor;
  border-radius: 2px;
  outline: 0;

  cursor: pointer;
  color: $defaultFontColor;

  font-size: 14px;
  font-weight: 500;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  &:hover {
    border: 1px solid $disabledBorderColor;
    background-color: $disabledBorderColor;
  }

  &.brand {
    border: 1px solid $brandColor;
    color: $brandColor;
    background-color: $white;

    &:hover {
      border: 1px solid $brandColor;
      background-color: $brandColor;
      color: $white;
    }
  }

  &.small {
    min-height: 23px;
    min-width: 47px;
  }

  &.disabled {
    border: 1px solid $disabledBorderColor!important;
    color: $defaultBorderColor!important;

    cursor: not-allowed!important;

    &:hover {
      border: 1px solid $disabledBorderColor!important;
      color: $defaultBorderColor!important;
      background: none!important;
    }
  }

  &.critical {
    border: 1px solid $errorRed;
    color: $errorRed;
    background-color: $white;

    &:hover {
      border: 1px solid $errorRed;
      background-color: $errorRed;
      color: $white;
    }
  }

  &.warn {
    border: 1px solid #ee9725;
    color: #ee9725;
    background-color: $white;

    &:hover {
      border: 1px solid #ee9725;
      background-color: #ee9725;
      color: $white;
    }
  }
}
</style>
