<template>
  <span
    class="stringItem regular-13"
    :style="clickStyle"
    @click="emitId"
  >
    <p4a-icon
      v-if="item.success && !item.hideIcon"
      :color="'#88C440'"
      :icon="'result-passed'"
      class="icon icon--passed"
    />
    <p4a-icon
      v-if="!item.success && !item.hideIcon"
      :color="'#EA4E42'"
      :icon="'result-failed'"
      class="icon icon--failed"
    />
    <span :class="item.success ? 'coloredGreen' : 'coloredRed'">
      {{ item.text }}
    </span>
  </span>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    associatedId: {
      type: String,
      default: null
    }
  },

  computed: {
    clickStyle () {
      return this.associatedId ? 'cursor: pointer;' : null
    }
  },

  methods: {
    emitId () {
      this.$parent.$emit('itemClick', this.associatedId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.icon {
  margin-right: 5px;
  width: 13px;
}

.stringItem {
  width: 100%;
  margin: 0;
  display: flex;
  min-height: 15px;
  padding-top: 3px;
}

.coloredGreen { color: $successful; }

.coloredRed { color: $unsuccessful; }
</style>
