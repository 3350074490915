export const permissionsMixin = {
  computed: {
    permissions () {
      return this.$store.getters.permissions
    }
  },

  methods: {
    validateValue (value) {
      return value !== null && value !== '' && typeof value !== 'undefined'
    },

    checkPermission (requiredPermission) {
      if (requiredPermission) {
        return this.validateValue(this.permissions[requiredPermission])
      } else return true
    }
  }
}
