import Vue from 'vue'
import Router from 'vue-router'

// Loads prefered language of selected application and then opens page
import { loadLanguageAsync } from '@/wrapper/language'

import Unauthorised from '@/wrapper/Account/Unauthorised'
import Login from '@/wrapper/Account/Login'
import RequestPassword from '@/wrapper/Account/RequestPassword'
import ResetPassword from '@/wrapper/Account/ResetPassword'
import EmailSent from '@/wrapper/Account/EmailSent'

import Dashboard from '@/wrapper/views/dashboard/Dashboard'

import BrandCheck from '@/brandcheck/router'
import OntruimCheck from '@/ontruimcheck/router'

import { actionTypes } from '@/common/store/sessionStore'

Vue.use(Router)

const routes = [
  {
    path: '/login',
    component: Unauthorised,
    meta: { application: 'login' },
    children: [
      {
        path: '/',
        name: 'login',
        component: Login,
        meta: { application: 'login', allowAnonymous: true }
      },
      {
        path: '/resetpassword/:tokenId',
        name: 'resetpassword',
        component: ResetPassword,
        meta: { application: 'login', allowAnonymous: true }
      },
      {
        path: '/requestpassword',
        name: 'requestpassword',
        component: RequestPassword,
        meta: { application: 'login', allowAnonymous: true }
      },
      {
        path: '/emailsent',
        name: 'passwordemailsent',
        component: EmailSent,
        meta: { application: 'login', allowAnonymous: true }
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { application: 'wrapper' }
  },
  {
    path: '/entities',
    name: 'entities',
    component: () => import(/* webpackChunkName: "entities" */ '@/wrapper/views/entities/Entities.vue'),
    meta: { application: 'wrapper' }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '@/wrapper/views/users/OverviewUser.vue'),
    meta: { application: 'wrapper' }
  },
  {
    path: '/drawings',
    name: 'drawings',
    component: () => import(/* webpackChunkName: "drawings" */ '@/wrapper/views/drawings/OverviewDrawing.vue'),
    meta: { application: 'wrapper' }
  }
]

if (process.env.VUE_APP_BRANDCHECK === 'true') {
  routes.push({
    path: '/brandcheck',
    name: 'brandcheck',
    component: () => import(/* webpackChunkName: "brandcheck" */ '@/brandcheck/BrandCheck.vue'),
    children: BrandCheck,
    meta: { application: 'brandcheck' }
  })
}

if (process.env.VUE_APP_ONTRUIMCHECK === 'true') {
  routes.push({
    path: '/ontruimcheck',
    name: 'ontruimcheck',
    component: () => import(/* webpackChunkName: "ontruimcheck" */ '@/ontruimcheck/OntruimCheck.vue'),
    children: OntruimCheck,
    meta: { application: 'ontruimcheck' }
  })
}

const router = new Router({
  mode: 'history',
  routes: routes
})

router.beforeEach((to, from, next) => {
  const authToken = window.localStorage.getItem('auth-token')

  if (!router.app.$store.getters.isAuthenticated && authToken && authToken !== null) {
    router.app.$store.dispatch(actionTypes.VALIDATE).then(() => {
      handleNavigation(to, from, next)
    })
  } else {
    handleNavigation(to, from, next)
  }
})

function handleNavigation (to, from, next) {
  const language = 'nl'
  // If not logged in and trying to open the app
  if (to.meta.application !== 'login' && !router.app.$store.getters.isAuthenticated && !to.meta.allowAnonymous) {
    // Reroute to login page
    loadLanguageAsync(language, 'wrapper').then(() => next('/login'))
  } else if (to.meta.application === 'login' && router.app.$store.getters.isAuthenticated) {
    // If logged in and trying to open the login page
    if (to.name === 'resetpassword' || to.name === 'requestpassword') {
      // Resetting password so logout and continue to reset password page
      router.app.$store.dispatch(actionTypes.LOGOUT).then(() => {
        loadLanguageAsync(language, 'wrapper').then(() => next(to.path))
      })
    } else {
      // and not resetting password, go to home page
      loadLanguageAsync(language, 'wrapper').then(() => next('/dashboard'))
    }
  } else if (to.matched.length === 0) {
    loadLanguageAsync(language, 'wrapper').then(() => next('/dashboard'))
  } else {
    // Open the requested page
    loadLanguageAsync(language, to.matched[0].meta.application).then(() => next())
  }
}

export default router
