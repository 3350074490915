<template>
  <div>
    <form
      class="form"
      @keyup.enter="submit"
    >
      <p4a-input
        v-model="email"
        :placeholder="$t('login.email')"
        :left-icon="'user'"
        :full-width="true"
        :warning="{visible: error, showIcon: true}"
        :size="'large'"
        class="form__input"
      />
      <p4a-button
        :text="$t('general.next')"
        :size="'large'"
        :product="'tbq'"
        :full-width="true"
        class="form_button"
        @click="submit"
      />
      <!-- call to api/password/reset/request, prams in body: email -->
      <a
        href="#"
        class="form__link"
        @click="$router.push({name: 'login'})"
      >
        {{ $t('general.back') }}
      </a>
      <a
        :href="tokenRouteString"
        class="form__link"
      >
        {{ tokenRouteString }}
      </a>
    </form>
  </div>
</template>

<script>
import * as accountApi from '@/common/API/AccountApi'

export default {
  data () {
    return {
      error: false,
      email: '',
      tokenRouteString: ''
    }
  },
  mounted () {
    const self = this
    self.$emit('loadForm', self.sliderProperties)
  },
  methods: {
    submit () {
      const user = this
      const email = user.email.toLowerCase()
      if (this.email !== '') {
        accountApi.passwordRequest(email).then(() => {
          this.$router.push({ name: 'passwordemailsent', query: { to: user.email } })
        })
      } else {
        this.error = true
        this.$p4aToast.show(this.$t('toast.error'), this.$t('login.incorrectEmail'), 'error', 4000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.form {

  &__input { margin-bottom: rem(15); }

  &__button {}

  &__link {
    width: 100%;
    height: rem(38);
    text-align: center;
    font-size: rem(13);
    margin-top: rem(30);
    line-height: rem(38);
    display: inline-block;
    text-decoration: none;
    color: $defaultFontColor;
  }
}
</style>
