<template>
  <div class="login-container">
    <div class="login-side-bar">
      <div class="input-form">
        <carousel />
        <router-view @loadForm="loadSlider($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from './Carousel'

export default {
  name: 'Login',
  components: {
    Carousel
  },
  data () {
    return {
      showSignUpModal: false,
      sliderProperties: {
        sliderImagePath: ('/asset/wrapper/tbq-logo.svg'),
        sliderImageStyle: 'default',
        sliderTitle: '',
        sliderText: ''
      }
    }
  },
  methods: {
    loadSlider (slider) {
      const self = this
      self.sliderProperties = slider
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';
.login-container {

  .login-side-bar {
    width: rem(400);
    height: calc(100vh - #{rem(50)});
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    background-color: $white;
    border-right: solid rem(1) $infoGray;
    padding: rem(25) rem(25) rem(75) rem(25);

    .slider {
      padding: 0;
      text-align: center;

      img {
        width: 100%;
        height: auto;

        &.email-sent {
          width: auto;
          height: 50%;
          margin-top: rem(100);
        }
      }
    }
  }

  #featureTitle {
    display: block;
    font-size: 15pt;
    font-weight: 500;
    color: $textColor;
    text-align: center;
    line-height: rem(21);
    padding: rem(13) rem(25) rem(10);
  }

  #featureDescription {
    display: block;
    font-weight: 100;
    text-align: center;
    font-size: rem(12);
    padding: 0 rem(25) 0;
    line-height: rem(21);
    color: $defaultFontColor;
  }
}
</style>
