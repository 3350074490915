export default [
  {
    path: '/',
    redirect: 'wizard/new'
  },
  {
    path: 'wizard/:configurationId',
    name: 'wizard',
    component: () => import(/* webpackChunkName: "ontruimcheck" */ '@/ontruimcheck/views/Wizard/Wizard.vue')
  },
  {
    path: 'result/:configurationId',
    name: 'result',
    component: () => import(/* webpackChunkName: "ontruimcheck" */ '@/ontruimcheck/views/Result/Result.vue'),
    meta: { relatedMenu: 'wizard' }
  },
  {
    path: 'list',
    name: 'list',
    component: () => import(/* webpackChunkName: "ontruimcheck" */ '@/ontruimcheck/views/List/ConfigurationList.vue')
  },
  {
    path: 'detail/:configurationId',
    name: 'detail',
    component: () => import(/* webpackChunkName: "ontruimcheck" */ '@/ontruimcheck/views/Detail/Detail.vue'),
    meta: { relatedMenu: 'list' }
  }
]
