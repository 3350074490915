import * as _ from 'lodash'

import * as scanApi from '@/brandcheck/API/ScanApi'
import * as analysisApi from '@/brandcheck/API/AnalysisApi'

export const actionTypes = {
  GET_SCAN: 'SCANS/GET_SCAN',
  GET_DEFECTS: 'SCANS/GET_DEFECTS',
  GET_PINS: 'SCANS/GET_PINS',
  GET_QUESTIONSANDANSWERS: 'SCANS/GET_QUESTIONSANDANSWERS',
  GET_SCANTYPES: 'SCANS/GET_SCANTYPES',
  GET_SCANTYPES_BY_ENTITYTYPE: 'SCANS/GET_SCANTYPES_BY_ENTITYTYPE',
  REFRESH: 'SCANS/REFRESH'
}

export const mutationTypes = {
  ADD_SCAN: 'SCANS/ADD_SCAN',
  ADD_DEFECTS: 'SCANS/AD_DEFECTS',
  ADD_PINS: 'SCANS/ADD_PINS',
  ADD_QUESTIONSANDANSWERS: 'SCANS/AD_QUESTIONSANDANSWERS',
  ADD_SCANTYPES: 'SCANS/ADD_SCANTYPES',
  CLEAR_SCANS: 'SCANS/CLEAR_SCANS'
}

const state = {
  scans: [],
  scanTypes: [],
  defects: null,
  questionsAndAnswers: null,
  pins: []
}

const getters = {
  scans: (state) => state.scans,
  defects: (state) => state.defects,
  scanTypes: (state) => state.scanTypes,
  questionsAndAnswers: (state) => state.questionsAndAnswers,
  pins: state => state.pins
}

const actions = {
  [actionTypes.GET_SCAN] ({ commit }, scanId) {
    return scanApi.getScanById(scanId).then((scanResult) => {
      commit(mutationTypes.ADD_SCAN, scanResult)
    })
  },

  [actionTypes.GET_DEFECTS] ({ commit }, scanId) {
    return analysisApi.getDefects(scanId).then((defects) => {
      commit(mutationTypes.ADD_DEFECTS, defects)
    })
  },

  [actionTypes.GET_PINS] ({ commit }, scanId) {
    return analysisApi.getDefectsPinInstances(scanId).then(pins => {
      commit(mutationTypes.ADD_PINS, pins)
    })
  },

  [actionTypes.GET_QUESTIONSANDANSWERS] ({ commit }, scanId) {
    return analysisApi.getQuestionsAndAnswers(scanId).then((questionsAndAnswers) => {
      commit(mutationTypes.ADD_QUESTIONSANDANSWERS, questionsAndAnswers)
    })
  },

  [actionTypes.GET_SCANTYPES] ({ commit }) {
    return scanApi.getAllScanTypes().then((scanTypes) => {
      commit(mutationTypes.ADD_SCANTYPES, scanTypes)
    })
  },

  [actionTypes.REFRESH] ({ commit }) {
    commit(mutationTypes.CLEAR_SCANS)
  },

  [actionTypes.GET_SCANTYPES_BY_ENTITYTYPE] ({ commit }, entityTypeId) {
    return scanApi.getScanTypesByEntityType(entityTypeId)
  }
}

const mutations = {
  [mutationTypes.ADD_SCAN] (state, scanResult) {
    if (scanResult && scanResult !== null) {
      state.scans = _.concat(state.scans, scanResult)
    }
  },

  [mutationTypes.ADD_DEFECTS] (state, defects) {
    state.defects = defects
  },

  [mutationTypes.ADD_PINS] (state, pins) {
    state.pins = pins
  },

  [mutationTypes.ADD_QUESTIONSANDANSWERS] (state, questionsAndAnswers) {
    state.questionsAndAnswers = questionsAndAnswers
  },
  [mutationTypes.ADD_SCANTYPES] (state, scanTypes) {
    state.scanTypes = scanTypes
  },
  [mutationTypes.CLEAR_SCANS] (state) {
    state.scans = []
  }
}

export const storeModule = {
  state,
  getters,
  actions,
  mutations
}
