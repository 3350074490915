<template>
  <div class="cta__card__container">
    <div class="header">
      {{ configuration.header }}
    </div>
    <div class="header header--sub">
      {{ configuration.subHeader }}
    </div>
    <div class="data__row">
      <p4a-icon
        class="data__row--icon"
        :icon="configuration.icon"
        :color="'#19A49A'"
      />
      <div class="data__row--number">
        {{ configuration.number }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    configuration: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.cta__card__container {
  width: rem(275);
  height: rem(175);
  padding: rem(25) 0;
  vertical-align: top;
  display: inline-block;
  background-color: $white;
  box-sizing: border-box!important;
  border: rem(1) solid $defaultBorderColor;

  .header {
    margin: 0 auto;
    text-align: center;
    font-size: rem(17);
    line-height: rem(25);
    background-color: $white;

    &--sub {
      color: $infoGray;
      font-size: rem(13);
    }
  }

  .data__row {
    align-content: center;
    text-align: center;
    font-size: rem(13);
    margin: rem(15) auto;

    &--number {
      color: $textColor;
      line-height: 34px;
      font-size: 34px;
      margin-right: rem(10);
      display: inline-block;
      vertical-align: top;
    }

    &--icon {
      height: rem(34);
      display: inline-block;
      margin-right: rem(10);
      max-width: 34px;
    }

    &--rate {
      display: inline-block;
    }
  }
}
</style>
