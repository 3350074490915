import { postJson, getJson } from './../../common/API/BaseApi.js'

const baseRoute = '/api/account'

export const passwordRequest = function (email) {
  return postJson({
    url: `${baseRoute}/reset/request`,
    data: {
      Email: email
    }
  })
}

export const changePassword = function (password, tokenId) {
  return postJson({
    url: `${baseRoute}/reset/${tokenId}`,
    data: {
      Password: password
    }
  })
}

export const validateToken = function (tokenId) {
  return getJson({
    url: `${baseRoute}/reset/token/validate/${tokenId}`,
    data: {
      Token: tokenId
    }
  })
}

export const authenticateUser = function ({ email, password }) {
  return postJson({
    url: `${baseRoute}/authenticate`,
    data: {
      Email: email,
      Password: password
    }
  })
}

export const validateUser = function () {
  return getJson({
    url: `${baseRoute}/validate`
  }).catch(function (data) {
    if (data.statusCode === 401) {
      return null
    }
  })
}

export const saveDashboardConfiguration = function (dashboardConfig) {
  const config = JSON.stringify(dashboardConfig)
  return postJson({
    url: `${baseRoute}/config/dashboard`,
    data: { config }
  })
}

export const saveNavigationConfiguration = function (isCollapsed) {
  return postJson({ url: `${baseRoute}/config/navigation`, data: { isCollapsed } })
}

export const validateLicenses = function () {
  return getJson({
    url: `${baseRoute}/validate/license`
  })
}

export const validatePermissions = function () {
  return getJson({
    url: `${baseRoute}/validate/permissions`
  })
}
