import * as definitionApi from '../API/DefinitionApi'

export const actionTypes = {
  GET_ALL_AMBITION_GOALS: 'AMB/GET_ALL_AMBITION_GOALS',
  GET_ALL_GOALS: 'AMB/GET_ALL_GOALS'
}

export const mutationTypes = {
  SET_AMBITION_GOALS: 'AMB/SET_AMBITION_GOALS',
  SET_GOALS: 'AMB/SET_GOALS'
}

const state = {
  goals: [],
  ambitionGoals: []
}

const getters = {
  ambitionGoals: (state) => state.ambitionGoals,
  goals: (state) => state.goals
}

const actions = {
  [actionTypes.GET_ALL_AMBITION_GOALS] ({ commit }) {
    return definitionApi.getAmbitionGoals().then(ambitionGoalResult => {
      commit(mutationTypes.SET_AMBITION_GOALS, ambitionGoalResult
      )
    })
  },
  [actionTypes.GET_ALL_GOALS] ({ commit }) {
    return definitionApi.getGoals().then(GoalResult => {
      commit(mutationTypes.SET_GOALS, GoalResult
      )
    })
  }
}

const mutations = {
  [mutationTypes.SET_AMBITION_GOALS] (state, ambitionGoalResult) {
    state.ambitionGoals = ambitionGoalResult
  },
  [mutationTypes.SET_GOALS] (state, GoalResult) {
    state.goals = GoalResult
  }
}

export const storeModule = {
  state,
  getters,
  actions,
  mutations
}
