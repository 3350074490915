let brandcheckApiEndpoint
let ontruimcheckApiEndpoint

export const setBrandcheckApiEndpoint = (endpoint) => {
  brandcheckApiEndpoint = endpoint
}

export const setOntruimcheckApiEndpoint = (endpoint) => {
  ontruimcheckApiEndpoint = endpoint
}

export const getBrandcheckApiEndpoint = () => {
  return brandcheckApiEndpoint
}

export const getOntruimcheckApiEndpoint = () => {
  return ontruimcheckApiEndpoint
}
