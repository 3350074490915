<template>
  <div class="cta__container">
    <div class="cta__left">
      <div class="cta__left--header">
        {{ welcomePrefix }} {{ userName }}!
      </div>
      <div class="cta__left--body">
        <span v-if="insufficientScans">
          {{ $t('dashboard.insufficientScans') }}
        </span>
        <span v-else>
          {{ $tc('dashboard.thereIs', totalScans + totalDefects) }}
          <span class="highlighted">
            {{ totalScans }}
            {{ $tc('dashboard.analysis', totalScans) }}
          </span>
          {{ $tc('dashboard.withACombined') }}
          <span class="highlighted">
            {{ totalDefects }}
            {{ $tc('dashboard.defect', totalDefects) }}.
          </span>
          <span v-if="nothingToResolve">
            {{ $t('dashboard.nothingToResolve') }}
          </span>
          <span v-else>
            {{ $tc('dashboard.solveResultPreface1', totalDefects) }}
            {{ $tc('dashboard.solveResultPreface2', resolvableBuildings + resolvableBuildingParts + resolvableResidences) }}
            <span v-if="resolvableBuildings > 0">
              <span class="highlighted">
                {{ resolvableBuildings }}
                {{ $tc('dashboard.building', resolvableBuildings) }}
              </span>
            </span>
            <span v-if="resolvableBuildingParts > 0">
              <span v-if="resolvableBuildings > 0">
                ,
              </span>
              <span class="highlighted">
                {{ resolvableBuildingParts }}
                {{ $tc('dashboard.buildingPart', resolvableBuildingParts) }}
              </span>
            </span>
            {{ $t('dashboard.willMeetAmbition') }}
            <span v-if="resolvableResidences > 0">
              <span v-if="resolvableBuildings > 0 || resolvableBuildingParts > 0">
                {{ $t('dashboard.solveResultAdditional') }}
              </span>
              <span class="highlighted">
                {{ resolvableResidences }}
                {{ $tc('dashboard.residence', resolvableResidences) }}
              </span>
              {{ $t('dashboard.solveResultEnd1') }}
              <span class="highlighted">
                {{ numberOfOpenTasks }}
              </span>
              {{ $t('dashboard.solveResultEnd2') }}
            </span>
          </span>
        </span>
      </div>

      <div class="cta__left--buttons">
        <p4a-button
          :product="'tbq'"
          :size="'large'"
          :text="$t('dashboard.toAnalysis')"
          style="marginRight: 25px"
          @click="navTo('analyseBuilding')"
        />

        <p4a-button
          :product="'tbq'"
          :size="'large'"
          :text="$t('dashboard.toTaskManagement')"
          @click="navTo('taskManagement')"
        />
      </div>
    </div>

    <div class="cta__right">
      <action-card :configuration="taskCardConfig" />
      <action-card :configuration="ambitionCardConfig" />
      <action-card
        :configuration="defectCardConfig"
        style="marginRight: 0px"
      />
    </div>
  </div>
</template>

<script>
import ActionCard from './ActionCard'

export default {
  components: {
    ActionCard
  },

  props: {
    totalScans: {
      type: Number,
      default: 0
    },
    totalDefects: {
      type: Number,
      default: 0
    },
    numberOfOpenTasks: {
      type: Number,
      default: 0
    },
    totalGoalsMet: {
      type: Number,
      default: 0
    },
    resolvableBuildings: {
      type: Number,
      default: 0
    },
    resolvableBuildingParts: {
      type: Number,
      default: 0
    },
    resolvableResidences: {
      type: Number,
      default: 0
    },
    insufficientScans: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    taskCardConfig () {
      return {
        header: 'Aantal taken',
        subHeader: 'Alle analyses',
        icon: 'adjust',
        number: this.numberOfOpenTasks,
        rate: '/m'
      }
    },

    ambitionCardConfig () {
      return {
        header: 'Behaalde ambities',
        subHeader: 'Complete gebouwen',
        icon: 'city',
        number: this.totalGoalsMet,
        rate: '/m'
      }
    },

    defectCardConfig () {
      return {
        header: 'Tekortkomingen',
        subHeader: 'Alle analyses',
        icon: 'status',
        number: this.totalDefects,
        rate: '/m'
      }
    },

    userName () {
      const authenticatedUser = this.$store.getters.authenticatedUser
      return authenticatedUser ? authenticatedUser.FirstName : ''
    },

    nothingToResolve () {
      return this.resolvableBuildings === 0 &&
        this.resolvableBuildingParts === 0 &&
        this.resolvableResidences === 0
    },

    welcomePrefix () {
      const currentHour = new Date().getHours()

      if (currentHour > 5 && currentHour <= 12) {
        return this.$t('dashboard.goodMorning')
      } else if (currentHour > 12 && currentHour <= 17) {
        return this.$t('dashboard.goodAfternoon')
      } else {
        return this.$t('dashboard.goodEvening')
      }
    }
  },

  methods: {
    navTo (routeName) {
      this.$router.push({ name: routeName })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.cta {

  &__container {
    width: 100%;
    display: grid;
    padding: rem(50);
    grid-gap: rem(30);
    grid-template-rows: auto;
    grid-template-columns: 30% 70%;
  }

  &__left {
    display: inline-block;

    &--header {
      color: $brandColor;
      font-size: rem(25);
      margin-bottom: rem(10);
    }

    &--body {
      font-weight: 100;
      font-size: rem(17);
      line-height: rem(27);
      margin-bottom: rem(12);
    }

    &--buttons {
      width: 100%;
      display: inline-block;
      margin: rem(12) 0 rem(25) 0;
    }

    .button { display: inline-block; }

    .highlighted {
      color: $brandColor;
      font-weight: 400;
      display: inline-block;
    }
  }

  &__right {
    height: rem(175);
    display: grid;
    grid-gap: rem(25);
    vertical-align: top;
    box-sizing: border-box;
    grid-template-rows: auto;
    grid-template-columns: repeat(3, minmax(#{rem(100)}, #{rem(275)}));
  }
}
</style>
