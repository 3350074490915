import { postJson, deleteJson, getJson, putJson } from './BaseApi.js'

const baseRoute = '/api/user'

export const getAllSettings = function () {
  return getJson({ url: `${baseRoute}/settings` })
}

export const getAllUsers = function (searchParameter = '') {
  return getJson({ url: `${baseRoute}/all?searchparameter=${searchParameter}` })
}

export const getUserById = function (userId) {
  return getJson({ url: `${baseRoute}/${userId}` })
}

export const getAllUsersByType = function (userType) {
  return getJson({ url: `${baseRoute}/all?usertype=${userType}` })
}

export const deleteUser = function (userId) {
  return deleteJson({ url: `${baseRoute}/${userId}` })
}

export const editUser = function ({ userId, firstName, middleName, lastName, companyId, email, roleId, userType }) {
  return putJson({
    url: `${baseRoute}/${userId}`,
    data: {
      Id: userId,
      FirstName: firstName,
      MiddleName: middleName,
      LastName: lastName,
      TypeUser: userType,
      CompanyId: companyId,
      Email: email,
      RoleId: roleId
    }
  })
}

export const createUser = function ({ firstName, middleName, lastName, companyId, email, roleId, userType }) {
  if (userType === '0') {
    userType = 0
  }
  return postJson({
    url: `${baseRoute}`,
    data: {
      FirstName: firstName,
      MiddleName: middleName,
      LastName: lastName,
      TypeUser: userType,
      CompanyId: companyId,
      Email: email,
      RoleId: roleId
    }
  })
}
