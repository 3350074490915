export default [
  {
    path: '/',
    redirect: 'overviewscan'
  },
  {
    path: 'analysebuilding',
    name: 'analyseBuilding',
    component: () => import(/* webpackChunkName: "brandcheck" */ '@/brandcheck/views/analysis/AnalysisOverview'),
    meta: { routeIcon: '/asset/icons/analyse-brand.svg', name: 'analysis' }
  },
  {
    path: 'analysebuilding/:selectedEntityId',
    name: 'analyseBuildingDetail',
    component: () => import(/* webpackChunkName: "brandcheck" */ '@/brandcheck/views/analysis/detail/AnalysisDetail'),
    meta: { routeIcon: '/asset/icons/analyse-brand.svg', name: 'analysis', relatedMenu: 'analyseBuilding' }
  },
  {
    path: 'scandetail/:scanId',
    name: 'scanDetail',
    component: () => import(/* webpackChunkName: "brandcheck" */ '@/brandcheck/components/ScanDetail'),
    meta: { routeIcon: '/asset/icons/scans.svg', name: 'scanDetail', relatedMenu: 'overviewscan' }
  },
  {
    path: 'overviewbuilding',
    name: 'overviewBuilding',
    component: () => import(/* webpackChunkName: "brandcheck" */ '@/brandcheck/components/OverviewBuilding'),
    meta: { routeIcon: '$/brandcheck/assets/images/gebouwen.svg', name: 'buildingsOverview' }
  },
  {
    path: 'overviewscan',
    name: 'overviewscan',
    component: () => import(/* webpackChunkName: "brandcheck" */ '@/brandcheck/components/OverviewScan'),
    meta: { routeIcon: '/asset/icons/scans.svg', name: 'scans', menu: 'ScanMenu' }
  },
  {
    path: 'taskmanagement/',
    name: 'taskManagement',
    component: () => import(/* webpackChunkName: "brandcheck" */ '@/brandcheck/views/taskmanagement/TaskManagement.vue'),
    meta: { routeIcon: '/asset/icons/adjust.svg', name: 'taskManagement' }
  },
  {
    path: 'taskmanagement/:entityId/:categoryId/:answerId',
    name: 'taskManagementDeeplink',
    component: () => import(/* webpackChunkName: "brandcheck" */ '@/brandcheck/views/taskmanagement/TaskManagement.vue'),
    meta: { routeIcon: '/asset/icons/adjust.svg', name: 'taskManagement' }
  }
]
