<!-- CHANGED IN TEMPLATE:
--   Added @removeCard listener to component with editmode off, line 67
-->

<template>
  <div
    ref="dashboardMain"
    class="dashboard-main"
    :style="gridStyleComputed"
  >
    <div
      v-if="editMode"
      class="card-deck"
    >
      <div
        v-for="card in cards"
        :key="card.id"
        class="card-test editing"
        :draggable="editMode"
        :style="cardStyle(card)"
        @dragover="dragOver($event)"
        @dragstart="dragStart(card, $event)"
        @drag="drag(card, $event)"
        @dragend="drop(card)"
      >
        <component
          :is="cardComponents[card.cardComponent]"
          :edit-mode="editMode"
          :my-card="card"
          :dimensions="{
            x: card.rect.width * (cellSize + border) - border - 2,
            y: card.rect.height * (cellSize + border) - border - 2
          }"
          @cardEmit="relayCardEmit"
          @saveSettings="saveSettingsForCard(card, $event)"
        />
        <div
          class="delete-button"
          @click="yeet(card)"
        >
          X
        </div>
      </div>
    </div>

    <div
      v-if="!editMode"
      class="card-deck"
    >
      <div
        v-for="card in dashboardCardsRearranged"
        :key="card.id"
        class="card-test"
        :draggable="editMode"
        :style="cardStyle(card)"
      >
        <component
          :is="cardComponents[card.cardComponent]"
          :edit-mode="editMode"
          :my-card="card"
          :dimensions="{
            x: card.rect.width * (cellSize + border) - border - 6,
            y: card.rect.height * (cellSize + border) - border - 6
          }"
          @cardEmit="relayCardEmit"
          @saveSettings="saveSettingsForCard(card, $event)"
          @removeCard="yeet(card)"
        />
      </div>
    </div>
    <svg
      v-if="editMode"
      class="dashboard-background"
      width="100%"
      height="100%"
      @dragover="dragOver($event)"
    >
      <defs>
        <pattern
          id="cross"
          :width="cellSize + border"
          :height="cellSize + border"
          patternUnits="userSpaceOnUse"
        >
          <line
            :x1="(border / 2)"
            :x2="(border / 2)"
            :y1="(border / 2) - (crossSize/2)"
            :y2="(border / 2) + (crossSize/2)"
          />
          <line
            :y1="(border / 2)"
            :y2="(border / 2)"
            :x1="(border / 2) - (crossSize / 2)"
            :x2="(border / 2) + (crossSize / 2)"
          />
        </pattern>
      </defs>
      <rect
        width="100%"
        height="100%"
        fill="url(#cross)"
      />
    </svg>
  </div>
</template>

<script>
import * as _ from 'lodash'
import * as uuidv4 from 'uuid-v4'
// CHANGED: COMMENTED NEXT LINE
// import * as dashboardApi from '../../Api/dashboardApi'

// CHANGED: Added API endpoints
import { getAllSettings } from '@/common/API/UserApi'
import { saveDashboardConfiguration } from '@/common/API/AccountApi'

export default {
  name: 'Dashboard',
  props: {
    scrollContainer: {
      type: HTMLElement,
      default: null
    },
    editMode: {
      default: false,
      type: Boolean
    },
    projectId: {
      default: '',
      type: String
    },
    cardComponents: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      height: 0,
      isSaving: false,
      inputString: '',
      showDebugLogs: false,
      gridSize: {
        x: 0,
        y: 0
      },
      clickOffset: {
        x: 0,
        y: 0
      },
      gridOnPagePosition: {
        x: 0,
        y: 0
      },
      crossToBorderRatio: 0.6,
      cellSize: 50, // CHANGED: Adjusted cellSize
      border: 25,
      cards: { },
      clientWindowSize: 200
    }
  },
  computed: {
    computedDashboardHeight () {
      return this.height * (this.cellSize + this.border) + this.border
    },
    crossSize () {
      return this.clamp(0, this.border, this.border * this.crossToBorderRatio)
    },

    /**
     * CHANGED: Removed authenticated user computed property
     */

    cellCount () {
      const self = this

      const cellDimension = (self.cellSize + self.border)
      const numX = Math.round(self.gridSize.x / cellDimension)
      const numY = Math.round(self.gridSize.y / cellDimension) - 1
      return { x: numX, y: numY }
    },
    layoutHeight () {
      const self = this
      let aggregateHeight = 0
      _.forEach(self.cards, function (card) {
        const bottom = card.rect.y + card.rect.height
        aggregateHeight = Math.max(aggregateHeight, bottom)
      })
      return aggregateHeight
    },
    gridStyleComputed () {
      const self = this
      return {
        height: self.gridSize.y + 'px',
        padding: self.border
      }
    },
    dashboardCardsRearranged () {
      const self = this
      let cardsCopy = _.cloneDeep(self.cards)
      cardsCopy = _.orderBy(cardsCopy, [item => item.rect.x + item.rect.y], 'asc')

      // Do we have to shuffle things around to get everything on screen?
      let rearrange = false
      _.forEach(cardsCopy, (card) => {
        if (!self.collisionCheck(card.rect, card.id, cardsCopy)) {
          rearrange = true
        }
      })

      // Fitting every screen.
      if (rearrange) {
        _.forEach(cardsCopy, (card) => {
          const proposedRect = _.clone(card.rect)
          proposedRect.x = 0
          proposedRect.y = 0
          let timeout = self.cellCount.x * self.cellCount.y
          while (!self.collisionCheck(proposedRect, card.id, cardsCopy) && timeout > 0) {
            timeout--
            proposedRect.x++
            if (proposedRect.x > self.cellCount.x) {
              proposedRect.x = 0
              proposedRect.y++
            }
            // CHANGED: Added '- 1' because the grid coordinate system starts at 0
            if (proposedRect.y + proposedRect.height - 1 > self.cellCount.y) {
              proposedRect.y = 0
            }
          }
          if (timeout > 0) {
            card.rect = proposedRect
          }
        })
      }
      return cardsCopy
    }
  },
  watch: {
    editMode (val) {
      if (val === false) {
        const self = this
        self.height = self.layoutHeight
        self.setGridSizeY()
      }
    }
  },
  created () {
    const self = this
    self.$nextTick(function () {
      window.addEventListener('resize', self.resizeDashboard)
      self.resizeDashboard()
      self.fetchDashboard()
    })
  },
  methods: {
    resizeDashboard () {
      const self = this
      const dashboardRef = self.$refs.dashboardMain
      if (!dashboardRef) {
        return
      }
      const clientRect = dashboardRef.getBoundingClientRect()
      self.gridOnPagePosition.x = clientRect.left
      self.gridOnPagePosition.y = clientRect.top
      self.gridSize.x = clientRect.width
      self.clientWindowSize = clientRect.height
      self.setGridSizeY()
    },
    yeet (card) {
      const self = this
      delete self.cards[card.id]
      self.forceReload()
    },
    forceReload () {
      const self = this
      if (self.isSaving) {
        return
      }
      self.isSaving = true
      self.saveDashboard().then(() => {
        // self.cards = {} CHANGED: Commented line to prevent blinking
        self.fetchDashboard().then(() => {
          // we have fetched a dashboard
          self.isSaving = false
          self.setGridSizeY()
        })
      })
    },
    makeCard ({ rect, cardComponent, settings }) {
      const self = this
      const id = uuidv4()
      let newRect = self.findSpace({ rect, id })
      if (newRect === null) {
        // Here it knows the dashboard is full
        if (self.scrollContainer) {
          self.makeRoom(rect.height).then((result) => {
            newRect = self.findSpace({ rect, id })
            if (!newRect) return false
            const newCard = {
              id: id,
              rect: newRect,
              cardComponent: cardComponent,
              dragging: false,
              settings: settings
            }
            self.cards[newCard.id] = newCard
            self.forceReload()
            return true
          })
        } else return false
      } else {
        const newCard = {
          id: id,
          rect: newRect,
          cardComponent: cardComponent,
          dragging: false,
          settings: settings
        }
        self.cards[newCard.id] = newCard
        self.forceReload()

        // CHANGED: Added return and return value
        return true
      }
    },
    makeRoom (extraRows) {
      return new Promise((resolve) => {
        const self = this
        self.height += extraRows
        self.setGridSizeY()
        resolve(true)
      })
    },
    relayCardEmit (eventParameters) {
      this.$emit('cardEmit', eventParameters)
    },
    findSpace (card) {
      const self = this
      const cardsCopy = _.clone(self.cards)
      const proposedRect = _.clone(card.rect)
      proposedRect.x = 0
      proposedRect.y = 0
      let timeout = self.cellCount.x * self.cellCount.y
      while (!self.collisionCheck(proposedRect, card.id, cardsCopy) && timeout > 0) {
        timeout--
        proposedRect.x++
        if (proposedRect.x > self.cellCount.x) {
          proposedRect.x = 0
          proposedRect.y++
        }
        if (proposedRect.y + proposedRect.height >= self.cellCount.y) {
          proposedRect.y = 0
        }
      }
      if (timeout > 0) {
        return proposedRect
      } else {
        return null
      }
    },
    saveSettingsForCard (card, settings) {
      const self = this
      card.settings = settings
      self.forceReload()
    },

    /**
     * CHANGED: Changed API function for getAllSettings
     * CHANGED: Changed result value and null check
     */
    fetchDashboard () {
      const self = this

      return new Promise((resolve) => {
        getAllSettings().then(result => {
          self.cards = JSON.parse(result.DashboardConfig)

          if (self.cards === null) {
            self.cards = {}
          }

          self.height = self.layoutHeight

          self.setGridSizeY()
          resolve(result)
        })
      })
    },
    clamp (min, max, val) {
      return Math.min(Math.max(min, val), max)
    },
    drop (card) {
      const self = this
      if (card.dragging) {
        card.dragging = false
        self.saveDashboard()
      }
    },

    /**
     * CHANGED: Changed API function for saveDashboardConfiguration
     */
    saveDashboard () {
      const self = this
      return new Promise((resolve) => {
        saveDashboardConfiguration(self.cards).then((result) => {
          if (self.showDebugLogs) {
            console.log('Card layout was saved: ' + result)
          }
          resolve(result)
        })
      })
    },
    setGridSizeY () {
      const self = this
      self.gridSize.y = Math.max(self.computedDashboardHeight, self.clientWindowSize)
    },
    dragStart (card, event) {
      const self = this
      if (!self.editMode) {
        return
      }
      event.dataTransfer.effectAllowed = 'move'
      card.dragging = true
      self.clickOffset.x = event.offsetX
      self.clickOffset.y = event.offsetY
    },
    dragOver (event) {
      event.dataTransfer.dropEffect = 'move'
      event.preventDefault()
    },
    drag (card, event) {
      const self = this
      if (event.x === 0 || !self.editMode) {
        return
      }
      const rect = card.rect
      const scrollTop = self.scrollContainer ? self.scrollContainer.scrollTop : 0
      const proposedX = Math.floor((event.x - self.gridOnPagePosition.x - self.clickOffset.x + (self.cellSize / 2)) / (self.border + self.cellSize))
      const proposedY = Math.floor((event.y - self.gridOnPagePosition.y + scrollTop - self.clickOffset.y + (self.cellSize / 2)) / (self.border + self.cellSize))

      if (proposedX === rect.x && proposedY === rect.y) return
      const proposedRect = { x: proposedX, y: proposedY, width: rect.width, height: rect.height }

      if (self.collisionCheck(proposedRect, card.id, self.cards)) {
        // Collision check is expensive. Only do it when jumping cells not on drag ticks.
        rect.x = proposedX
        rect.y = proposedY
      }
    },
    collisionCheck (rect, cardId, cards) { // returns true if no collision
      const self = this
      let collision = false

      // Bound check
      if (rect.x < 0 ||
          rect.y < 0 ||
          rect.x + rect.width > self.cellCount.x ||
          rect.y + rect.height > self.cellCount.y + 1) {
        if (rect.y + rect.height > self.cellCount.y && self.scrollContainer) {
          self.makeRoom(rect.height)
        }
        return false
      }

      const upperLeftCorner1 = { x: rect.x, y: rect.y }
      const lowerLeftCorner1 = { x: rect.x + rect.width - 1, y: rect.y + rect.height - 1 }
      let upperLeftCorner2 = { x: 0, y: 0 }
      let lowerLeftCorner2 = { x: 0, y: 0 }
      let otherRect = _.clone(rect)
      let noOverlapX = false
      let noOverlapY = false

      _.forEach(cards, otherCard => {
        otherRect = otherCard.rect

        if (otherCard.id !== cardId) {
          upperLeftCorner2 = { x: otherRect.x, y: otherRect.y }
          lowerLeftCorner2 = { x: otherRect.x + otherRect.width - 1, y: otherRect.y + otherRect.height - 1 }

          // Check for overlap
          noOverlapX = (upperLeftCorner1.x > lowerLeftCorner2.x || lowerLeftCorner1.x < upperLeftCorner2.x)
          noOverlapY = (upperLeftCorner1.y > lowerLeftCorner2.y || lowerLeftCorner1.y < upperLeftCorner2.y)

          if (!noOverlapX && !noOverlapY) {
            collision = true
          }
        }
      })

      return !collision
    },
    cardStyle (card) {
      const self = this
      const rect = card.rect
      const x = self.border + rect.x * (self.cellSize + self.border) + 'px'
      const y = self.border + rect.y * (self.cellSize + self.border) + 'px'

      if (rect.width < rect.minWidth) {
        rect.width = rect.minWidth
      }

      if (rect.height < rect.minHeight) {
        rect.height = rect.minHeight
      }

      return {
        'box-shadow': self.editMode ? '2px 3px 13px -5px rgba(0,0,0,0.75)' : '',
        opacity: card.dragging ? 0.5 : 1,
        pointer: card.dragging ? 'move' : 'default',
        width: (rect.width * (self.cellSize + self.border)) - self.border + 'px',
        height: (rect.height * (self.cellSize + self.border)) - self.border + 'px',
        transform: 'translate(' + x + ',' + y + ')'
      }
    }
  }
}
</script>

<style scoped lang="scss">
  // CHANGED: The reference to the definitions.scss
  @import 'pro4all-components/ui-kit/styling/definitions.scss';

  .dashboard-background {
    position: relative;
    line {
      stroke: $pending-blue; // CHANGED: The name of the SCSS variable
      stroke-width: 2;
      opacity: 0.5;
    }
  }
  .card-deck {
    position: relative;
  }
  .dashboard-main {
    overflow: hidden;
    background: $light-grey-background; // CHANGED: The name of the SCSS variable
    width: 100%;
    height: 100%;
    min-height: 100%;
  }

  .background-crosses {
    position: fixed;
  }

  .delete-button {
    position: absolute;
    cursor: pointer;
    padding: 5px;
    top: 0px;

    &:hover {
      color: $medium-grey-font; // CHANGED: The name of the SCSS variable
    }
  }

  .card-test {
    z-index: 1;
    transition: transform 0.1s ease;
    position: absolute;
    display: block;
    background: white;
    border: solid 1px $primary-border-color; // CHANGED: The name of the SCSS variable

     &.editing {
       cursor: move !important;
     }
  }
</style>
