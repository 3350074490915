<template>
  <div ref="dashboardWrapper">
    <tool-bar>
      <template slot="left">
        <div class="top-menu-icons">
          <div
            class="cards-menu-wrapper"
            @click="showDashboardCardsMenu = true"
          >
            {{ $t('topMenu.addCard') }}
          </div>
          <p4a-legacy-dropdown
            v-if="showDashboardCardsMenu"
            :large="true"
            class="top-menu-cards"
            @blur="showDashboardCardsMenu = false"
          >
            <p4a-legacy-dropdown-option
              v-for="card in availableCards"
              :key="card.id"
              :class="{'top-menu-cards--active': filter(card.module)}"
              @click.native="addCard(card.module)"
            >
              {{ $t(card.name) }}
            </p4a-legacy-dropdown-option>
          </p4a-legacy-dropdown>
        </div>
      </template>
    </tool-bar>

    <div
      ref="dashboardContainer"
      class="dashboard__container"
    >
      <call-to-action
        :total-scans="totalScans"
        :total-defects="totalDefects"
        :number-of-open-tasks="openTasks.length"
        :total-goals-met="totalGoalsMet"
        :resolvable-buildings="resolvableBuildings"
        :resolvable-building-parts="resolvableBuildingParts"
        :resolvable-residences="resolvableResidences"
        :insufficient-scans="insufficientScans"
        :task-rate="taskRate"
      />

      <dashboard
        ref="mainDashboard"
        class="dashboard__main"
        :card-components="cardComponents"
        :scroll-container="this.$refs.dashboardContainer"
      />
    </div>
  </div>
</template>

<script>
import { filter, each, some } from 'lodash'

import ToolBar from '@/common/Components/ToolBar/ToolBar'
import CallToAction from '@/wrapper/views/dashboard/CallToAction'
import Dashboard from '@/common/Components/IAN_Dashboard/Dashboard'

import CompanyDeadlines from './modules/CompanyDeadlines'
import Compliance711a from './modules/Compliance711a'
import AmbitionAllBuildings from './modules/AmbitionAllBuildings'

import * as analysisApi from '@/brandcheck/API/AnalysisApi'
import * as taskManagementApi from '@/brandcheck/API/TaskManagementApi'
import * as entityApi from '@/common/API/EntityApi'

export default {
  components: {
    ToolBar,
    CallToAction,
    Dashboard
  },

  data () {
    return {
      availableCards: [
        { id: 1, module: 'CompanyDeadlines', name: 'expiredScans', icon: 'check_box.svg' },
        { id: 2, module: 'Compliance711a', name: 'topMenu.compliance', icon: 'check_box.svg' },
        { id: 3, module: 'AmbitionAllBuildings', name: 'topMenu.ambitionAllBuildings', icon: 'check_box.svg' }
      ],

      cardComponents: {
        CompanyDeadlines,
        Compliance711a,
        AmbitionAllBuildings
      },

      showDashboardCardsMenu: false,
      showDebugLogs: false,
      progressSummaries: [],
      buildings: [],
      buildingParts: [],
      residences: [],
      defects: [],
      tasks: []
    }
  },

  computed: {
    authenticatedUser () {
      return this.$store.getters.authenticatedUser
    },
    totalScans () {
      let result = 0
      each(this.progressSummaries, summary => {
        result += summary.TotalScans
      })
      return result
    },
    totalGoalsMet () {
      let result = 0
      each(this.progressSummaries, summary => {
        each(summary.GoalProgress, progress => {
          if (progress.IsMet) {
            result++
          }
        })
      })
      return result
    },
    totalDefects () {
      return this.defects.length
    },
    openTasks () {
      return filter(this.tasks, function (t) { return t.TaskStatusId !== '63df9ad6-86cd-44f3-91c8-da4166e84d63' })
    },
    resolvableBuildings () {
      const self = this
      let result = 0
      each(this.buildings, building => {
        const buildingDefects = filter(self.defects, { EntityId: building.Id })
        const buildingTasks = filter(self.tasks, { EntityId: building.Id })
        if (buildingDefects.length === buildingTasks.length && buildingDefects.length !== 0) {
          result++
        }
      })
      return result
    },
    resolvableBuildingParts () {
      const self = this
      let result = 0
      each(this.buildingParts, buildingPart => {
        const buildingDefects = filter(self.defects, { EntityId: buildingPart.Id })
        const buildingTasks = filter(self.tasks, { EntityId: buildingPart.Id })
        if (buildingDefects.length === buildingTasks.length && buildingDefects.length !== 0) {
          result++
        }
      })
      return result
    },
    resolvableResidences () {
      const self = this
      let result = 0
      const residences =
        filter(this.entities, e => {
          return (e.EntityTypeId === 'bc2ab09c-c262-4f81-997f-78f974545378')
        })
      each(residences, residence => {
        const buildingDefects = filter(self.defects, { EntityId: residence.Id })
        const buildingTasks = filter(self.tasks, { EntityId: residence.Id })
        if (buildingDefects.length === buildingTasks.length && buildingDefects.length !== 0) {
          result++
        }
      })
      return result
    },
    insufficientScans () {
      let missingBuildingScans = false
      each(this.buildings, building => {
        if (find(this.progressSummaries, { EntityId: building.Id }) === null) {
          missingBuildingScans = true
        }
      })
      return this.totalDefects === 0 || missingBuildingScans
    },
    taskRate () {
      const currentMonth = new Date().getMonth()
      const taskThisMonth = filter(this.tasks, task => {
        return new Date(task.CreatedAt).getMonth() <= currentMonth && !task.Completed
      })
      return taskThisMonth.length
    }
  },
  mounted () {
    analysisApi.getAllEntityProgressSummaries().then(entityProgressSummaries => {
      each(entityProgressSummaries, entityProgressSummary => {
        this.progressSummaries.push(entityProgressSummary.ProgressSummary)
      })
    })
    entityApi.getAllBuildings().then(buildings => {
      this.buildings = buildings
      each(this.buildings, building => {
        entityApi.getBuildingPartByAncestorId(building.Id).then(buildingPart => {
          this.buildingParts = buildingPart
        })
        entityApi.getResidencesByAncestorId(building.Id).then(residences => {
          this.residences = residences
        })
      })
    })
    analysisApi.getAllDefects().then(defects => {
      this.defects = defects
    })
    taskManagementApi.getAllTasks().then(defectTasksResult => {
      this.tasks = defectTasksResult
      return this.taskRate
    })
  },

  methods: {
    filter (component) {
      if (
        this.$refs.mainDashboard.cards &&
        (component === 'CompanyDeadlines' || component === 'AmbitionAllBuildings')
      ) {
        return some(this.$refs.mainDashboard.cards, card => card.cardComponent === component)
      }
    },

    addCard (component) {
      if (!this.filter(component)) {
        const card = {
          cardComponent: component,

          rect: {
            height: 5,
            width: 8,
            minHeight: 5,
            minWidth: 8,
            x: 0,
            y: 0
          },

          settings: {}
        }

        this.$refs.mainDashboard.makeCard(card)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.dashboard {

  &__container {
    overflow: auto;
  }

  &__main {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    background-color: $white;
  }
}

.card {
  z-index: 1;
  display: block;
  position: absolute;
  border-radius: rem(2);
  background-color: $white;
  transition: transform 0.1s ease;
  border: solid rem(1) $defaultBorderColor;

  &__header {
    position: relative;

    &__name {
      width: 100%;
      text-align: center;
      font-size: rem(17);
      padding-top: rem(24);
      color: $defaultFontColor;

      &--sub {
        font-weight: 400;
        color: $infoGray;
        text-align: center;
        font-size: rem(13);
        margin-top: rem(11);
        margin-bottom: rem(25);
      }
    }
  }

  &__close {
    width: rem(16);
    height: rem(16);
    top: 0;
    right: 0;
    cursor: pointer;
    margin: rem(17);
    position: absolute;
  }
}

.cards-menu-wrapper {
  width: rem(150);
  height: rem(38);
  cursor: pointer;
  margin: rem(6) 0;
  position: relative;
  font-size: rem(13);
  padding: 0 rem(25);
  line-height: rem(38);
  display: inline-block;
  border-right: rem(1) solid $darkBorder;

  &:after {
    right: rem(10);
    content: "\E9B1";
    font-size: rem(9);
    position: absolute;
    font-family: pro4all-icons;
  }
}

.top-menu-cards {
  left: 5px !important;

  &--active {
    color: $disabledBorderColor !important;

    &:hover {
      cursor: not-allowed !important;;
      background-color: $white !important;
    }
  }
}

/deep/ .dropdown__container {
  max-height: 250px;
  overflow: auto;
}
</style>
