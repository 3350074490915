import Vue from 'vue'

import App from './App'
import { i18n } from './language'
import store from './store'
import router from './router'

import { getRoutingConfig } from '@/common/API/ConfigApi'
import { setBrandcheckApiEndpoint, setOntruimcheckApiEndpoint } from '@/common/proxyConfig'

import P4aUikitPlugin from 'pro4all-components/P4aUikitPlugin'
import 'pro4all-components/dist/pro4all-components.css'

Vue.use(P4aUikitPlugin)

export let vm = {}

Vue.prototype.$eventBus = new Vue()

localStorage.setItem('showNotifications', true)

getRoutingConfig().then(result => {
  setBrandcheckApiEndpoint(result.BrandcheckApiEndpoint)
  setOntruimcheckApiEndpoint(result.OntruimcheckApiEndpoint)
  vm = new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
