<template>
  <div>
    <form
      @keyup.enter="submit"
    >
      <div v-if="!resetEnd && validatedConfirmed">
        <p4a-input
          v-model="password"
          :placeholder="$t('resetPassword.passwordNew')"
          :type="'password'"
          :left-icon="'locked'"
          :size="'large'"
          :full-width="true"
        />
        <p4a-input
          v-model="confirmPassword"
          :placeholder="$t('resetPassword.passwordConfirm')"
          :type="'password'"
          :left-icon="'locked'"
          :size="'large'"
          :full-width="true"
        />

        <p4a-button
          :text="$t('resetPassword.reset')"
          :product="'tbq'"
          :full-width="true"
          :size="'large'"
          class="form__input"
          @click="submit"
        />

        <label for="new">
          <div
            v-if="!checkPasswordChars"
            class="error"
          >{{ $t('resetPassword.passwordRequired') }}</div>
        </label>
        <label for="confirm">
          <div
            v-if="!checkPasswordMatch"
            class="error"
          >{{ $t('resetPassword.passwordMismatch') }}</div>
        </label>
      </div>

      <div v-else>
        <h3 class="message">
          {{ resultMessage }}
        </h3>
        <router-link
          v-if="!success"
          class="link"
          to="/requestpassword"
          exact
        >
          <a>{{ $t('resetPassword.tryAgain') }}</a>
        </router-link>
        <router-link
          v-else
          class="link"
          to="/login"
          exact
        >
          <a>{{ $t('resetPassword.continueToLogIn') }}</a>
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import * as accountApi from '@/common/API/AccountApi'

export default {
  data () {
    return {
      tokenId: '',
      validatedConfirmed: false,
      resetEnd: false,
      success: false,
      resetError: false,
      resultMessage: '',
      email: '',
      password: '',
      confirmPassword: '',
      sliderProperties: {
        sliderImagePath: ('/asset/wrapper/tbq-logo.svg'),
        sliderImageStyle: 'email-sent',
        sliderTitle: this.$t('resetPassword.featureTitle'),
        sliderText: this.$t('resetPassword.featureDescription')
      }
    }
  },
  created () {
    const self = this
    self.tokenId = self.$route.params.tokenId
    this.tokenValidation()
  },
  methods: {
    tokenValidation () {
      accountApi.validateToken(this.tokenId).then(result => {
        this.validatedConfirmed = result
      })
    },
    submit () {
      if (this.validate()) {
        accountApi.changePassword(this.password, this.tokenId).then(result => {
          if (result) {
            this.resetEnd = true
            this.success = true
            this.resultMessage = this.$t('resetPassword.success')
          } else {
            self.resultMessage = this.$t('resetPassword.failed') + '. ' + this.$t('resetPassword.tryAgain') + '.'
          }
        })
      } else {
        this.$p4aToast.show(this.$t('resetPassword.failed'), this.$t('resetPassword.passwordRequirements'), 'error', 4000)
      }
    },
    // PASSWORD VALIDATION
    hasValue (value) {
      return typeof (value) !== 'undefined' && value !== null && value.toString().length > 0
    },
    checkPasswordChars () {
      const self = this
      const allowedExpression = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
      const result = allowedExpression.test(self.password) || !self.hasValue(self.confirmPassword)
      return result
    },
    // Checks if passwords match
    checkPasswordMatch () {
      const self = this
      return (
        (self.password === self.confirmPassword
        ) || !self.hasValue(self.password) || !self.hasValue(self.confirmPassword))
    },

    // Checks all inputs for empty strings
    checkNotEmpty () {
      return this.hasValue(this.password) && this.hasValue(this.confirmPassword)
    },

    // Validates all checks
    validate () {
      return (
        this.checkNotEmpty() &&
        this.checkPasswordMatch() &&
        this.checkPasswordChars()
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';
 @import '@/common/style/AccountForm.scss';
</style>
