<template>
  <base-card
    name="topMenu.compliance"
    @removeCard="$emit('removeCard')"
  >
    <div class="card-main">
      <div class="entity__select">
        <drop-down
          v-model="selectedBuilding"
          class="entity__select--menu"
          :options="buildings"
          :place-holder="$t('menu.entities')"
          :small="true"
          @input="getResultSummaries"
        />
      </div>

      <div class="lower">
        <sortable-grid
          v-if="listItems.length > 0"
          :hide-shadow="true"
          :grid-container-padding="false"
          :loading="loadingResults"
          :columns="columns"
          :items="listItems"
          :modules="modules"
          :show-header-border="false"
          :has-top-border="false"
          :has-bottom-border="false"
          :show-header="true"
          :show-paginator="true"
          :page-size="6"
          @itemClick="itemClick($event)"
        >
          <div class="grid__header">
            <div
              v-if="!loadingTotals"
              class="compliance"
            >
              <div
                v-if="totals.failed > 0"
                class="failed"
              >
                {{ $t('compliance.failed') }}
              </div>
              <div
                v-else
                class="success"
              >
                {{ $t('compliance.success') }}
              </div>
            </div>
          </div>
        </sortable-grid>
        <div
          v-if="!loadingResults && listItems.length === 0"
          class="no-data"
        >
          <h2>{{ $t('noDataAvailable') }}</h2>
        </div>
      </div>
    </div>
  </base-card>
</template>
<script>
import { each, map } from 'lodash'

import BaseCard from './BaseCard'

import SortableGrid from '@/brandcheck/components/Grids/SortableGrid'
import StringItem from '@/brandcheck/components/Modules/StringItem'
import GoalItem from '@/brandcheck/components/Modules/GoalItem'

import { getResultSummaryByEntityId } from '@/ontruimcheck/API/ResultApi'
import {
  getAllBuildings,
  getDepartmentsByAncestorId,
  getResidencesByAncestorId
} from '@/common/API/EntityApi'
import { getEvacuationPlanByConfiguration } from '@/ontruimcheck/API/EvacuationApi'

import DropDown from '../../../../common/Components/Forms/DropDown'

export default {
  components: {
    DropDown,
    SortableGrid,
    BaseCard
  },

  data () {
    return {
      modules: { StringItem, GoalItem },
      loadingResults: true,
      loadingTotals: true,
      selectedBuilding: {},
      buildings: {},
      listItems: [],
      totals: {}
    }
  },
  computed: {
    entityTypes () {
      return this.$store.getters.entityTypes
    },
    columns () {
      return [
        { name: this.$t('compliance.department'), type: 'StringItem', key: 'departmentName', sortBy: false, widthPercentage: 30 },
        { name: this.$t('compliance.goal'), type: 'GoalItem', key: 'complianceGoal', sortBy: false, widthPercentage: 30 },
        { name: 'SM', type: 'GoalItem', key: 'goalReachedSM', sortBy: false, compact: true, widthPercentage: 10 },
        { name: 'KB', type: 'GoalItem', key: 'goalReachedKB', sortBy: false, compact: true, widthPercentage: 10 },
        { name: 'MB', type: 'GoalItem', key: 'goalReachedMB', sortBy: false, compact: true, widthPercentage: 10 },
        { name: 'GB', type: 'GoalItem', key: 'goalReachedGB', sortBy: false, compact: true, widthPercentage: 10 }
      ]
    }
  },
  created () {
    const self = this
    getAllBuildings().then(buildingEntities => {
      const columns = {}
      each(buildingEntities, building => {
        columns[building.Name] = building
      })
      self.buildings = columns
      const firstKey = Object.keys(self.buildings)[0]
      if (self.buildings[firstKey]) {
        self.selectedBuilding = self.buildings[firstKey]
      }
      self.getResultSummaries()
    })
  },
  methods: {
    itemClick (val) {
      // console.log(val + ' - item clicked')
    },

    // clearModule () Clears listItems and sets loading = true
    clearModule () {
      const self = this
      this.loadingResults = true
      this.loadingTotals = true
      this.listItems = []
      return new Promise(function (resolve) {
        resolve(self.loadingResults === true && self.loadingTotals === true)
      })
    },

    // getResultSummaries () Uses the selected buildingId to fetch departments and ontruimcheck results.
    getResultSummaries () {
      const self = this
      this.clearModule().then(() => {
        let promise

        // Get Departments or Recidences based of EntityTypeId
        if (this.selectedBuilding.EntityTypeId === 'a3aa5a27-0ac5-4eb2-938e-45db6926dedd') {
          promise = getDepartmentsByAncestorId(this.selectedBuilding.Id)
        } else {
          promise = getResidencesByAncestorId(this.selectedBuilding.Id)
        }

        promise.then(entities => {
          const promises = map(entities, entity => {
            return getResultSummaryByEntityId(entity.Id).then(result => {
              if (result.length > 0) {
                result.buildingId = self.selectedBuilding.Id
                result.departmentName = entity.Name
                self.setItem(result)
              }
            })
          })

          Promise.all(promises).then(() => {
            self.loadingResults = false
          })
        })
      })
    },

    // setItem () Sets the complianceGoal result and saves it to listItems (per entity)
    setItem (result) {
      const self = this
      this.calculateComplianceResult(result).then(() => {
        const resultObject = {
          buildingId: result.buildingId,
          departmentName: result.departmentName,
          complianceGoal: {
            success: result.goalReached,
            text: self.$t('general.max') + ' ' + result.maxCasualties + ' ' + self.$tc('compliance.casualties', result.maxCasualties),
            hideIcon: true
          },
          goalReachedSM: { success: result.SM },
          goalReachedKB: { success: result.KB },
          goalReachedMB: { success: result.MB },
          goalReachedGB: { success: result.GB }
        }
        self.listItems.push(resultObject)
        self.calculateTotals()
      })
    },

    // calculateComplianceResult (summary) fetches maxCasualties by configurationId and determines whether or not the goal was met.
    // Current design dictates that ANY scenario where casualties > maxCasualties will result in the entity failing the complianceGoal.
    calculateComplianceResult (summary) {
      summary.SM = false
      summary.KB = false
      summary.MB = false
      summary.GB = false
      summary.goalReached = false
      const promises = []
      each(summary, scenario => {
        promises.push(
          getEvacuationPlanByConfiguration(scenario.configurationId).then(plan => {
            summary.maxCasualties = plan.maxCasualties
            const casualties = {
              SM: scenario.casualtySM,
              KB: scenario.casualtyKB,
              MB: scenario.casualtyMB,
              GB: scenario.casualtyGB
            }
            const keys = Object.keys(casualties)
            each(keys, key => {
              if (casualties[key] <= summary.maxCasualties) {
                summary[key] = true
                summary.goalReached = true
              }
            })
          })
        )
      })
      return Promise.all(promises)
    },

    calculateTotals () {
      let _totalPassed = 0
      let _totalFailed = 0
      each(this.listItems, resultItem => {
        resultItem.goalReachedSM.success ? _totalPassed += 1 : _totalFailed += 1
        resultItem.goalReachedKB.success ? _totalPassed += 1 : _totalFailed += 1
        resultItem.goalReachedMB.success ? _totalPassed += 1 : _totalFailed += 1
        resultItem.goalReachedGB.success ? _totalPassed += 1 : _totalFailed += 1
      })
      this.totals.passed = _totalPassed
      this.totals.failed = _totalFailed
      this.loadingTotals = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/style/style.scss';
@import '@/common/style/DashboardCard.scss';

.entity__select {
  width: 100%;

  &--menu {
    width: rem(175);
    margin: rem(5) auto;
  }
}

.grid__header {
  position: absolute;

  .compliance {
    height: rem(50);
    font-weight: 100;
    font-size: rem(17);
    line-height: rem(50);

    .failed { color: $unsuccessful; }

    .success { color: $successful; }
  }

  .pagination {
    height: rem(50);
    line-height: rem(50);
  }
}

.lower { padding-top: rem(25); }
</style>
