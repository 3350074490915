var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'disabled': _vm.disabled,
    'brand': _vm.brand,
    'small': _vm.small,
    'critical': _vm.critical,
    'warn': _vm.warn
  },attrs:{"tabindex":_vm.tabIndex},on:{"click":_vm.click,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.click.apply(null, arguments)}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }