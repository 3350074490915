import { storeModule as scanModule } from './scanModule'
import { storeModule as buildingModule } from './buildingModule'
import { storeModule as goalModule } from './ambitionGoalModule'
import { storeModule as mediaModule } from './mediaModule'

export default {
  modules: {
    scans: scanModule,
    goals: goalModule,
    building: buildingModule,
    media: mediaModule
  }
}
