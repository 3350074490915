import { postJson, deleteJson, getJson, putJson } from './BaseApi.js'

const baseRoute = '/api/location'

export const createLocations = function (newLocations) {
  return postJson({ url: `${baseRoute}`, data: newLocations })
}

export const deleteLocation = function (locationId) {
  return deleteJson({ url: `${baseRoute}/${locationId}` })
}

export const getAllLocations = function () {
  return getJson({ url: `${baseRoute}/all` })
}

export const updateLocations = function (updatedLocations) {
  return putJson({ url: `${baseRoute}`, data: updatedLocations })
}

export const getLocationSummaries = (searchParameter) => {
  return getJson({ url: `${baseRoute}/summaries?searParameter=${searchParameter}` })
}
