import * as _ from 'lodash'
import * as analysisApi from '../API/AnalysisApi'

export const actionTypes = {
  GET_MEDIA_FOR_SCAN: 'GET_MEDIA_FOR_SCAN',
  GET_PIN_INSTANCES: 'GET_PIN_INSTANCES',
  CLEAR_MEDIA: 'CLEAR_MEDIA'
}

export const mutationTypes = {
  ADD_MEDIA: 'ADD_MEDIA',
  ADD_PIN_INSTANCES: 'ADD_PIN_INSTANCES',
  CLEAR_MEDIA: 'CLEAR_MEDIA'
}

const state = {
  pinInstances: [],
  media: {}
}

const getters = {
  media: state => state.media,
  pinInstances: state => state.pinInstances
}

const actions = {
  [actionTypes.GET_MEDIA_FOR_SCAN] ({ commit }, mediaId) {
    return analysisApi.getMediaForScan(mediaId)
      .then(toBase64)
      .then(mediaSrc => {
        commit(mutationTypes.ADD_MEDIA, { Id: mediaId, Src: mediaSrc })
      }).catch(error => {
        if (error.statusCode !== 204) { throw error }
      })
  },

  [actionTypes.GET_PIN_INSTANCES] ({ commit }, scanId) {
    return analysisApi.getPinInstances(scanId).then(pinInstances => {
      commit(mutationTypes.ADD_PIN_INSTANCES, pinInstances)
    })
  },
  [actionTypes.CLEAR_MEDIA] ({ commit }, scanId) {
    commit(mutationTypes.CLEAR_MEDIA)
  }
}

const mutations = {
  [mutationTypes.ADD_MEDIA] (state, media) {
    state.media = {
      Id: media.Id,
      Src: media.Src
    }
  },

  [mutationTypes.ADD_PIN_INSTANCES] (state, pinInstances) {
    state.pinInstances = _.concat(state.pinInstances, pinInstances)
  },
  [mutationTypes.CLEAR_MEDIA] (state) {
    state.media = null
    state.drawing = null
    state.pinInstances = []
  }
}

const toBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      resolve(reader.result.toString())
    }, false)
    reader.readAsDataURL(blob)
  })
}

export const storeModule = {
  state,
  getters,
  actions,
  mutations
}
