var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dashboardMain",staticClass:"dashboard-main",style:(_vm.gridStyleComputed)},[(_vm.editMode)?_c('div',{staticClass:"card-deck"},_vm._l((_vm.cards),function(card){return _c('div',{key:card.id,staticClass:"card-test editing",style:(_vm.cardStyle(card)),attrs:{"draggable":_vm.editMode},on:{"dragover":function($event){return _vm.dragOver($event)},"dragstart":function($event){return _vm.dragStart(card, $event)},"drag":function($event){return _vm.drag(card, $event)},"dragend":function($event){return _vm.drop(card)}}},[_c(_vm.cardComponents[card.cardComponent],{tag:"component",attrs:{"edit-mode":_vm.editMode,"my-card":card,"dimensions":{
          x: card.rect.width * (_vm.cellSize + _vm.border) - _vm.border - 2,
          y: card.rect.height * (_vm.cellSize + _vm.border) - _vm.border - 2
        }},on:{"cardEmit":_vm.relayCardEmit,"saveSettings":function($event){return _vm.saveSettingsForCard(card, $event)}}}),_c('div',{staticClass:"delete-button",on:{"click":function($event){return _vm.yeet(card)}}},[_vm._v(" X ")])],1)}),0):_vm._e(),(!_vm.editMode)?_c('div',{staticClass:"card-deck"},_vm._l((_vm.dashboardCardsRearranged),function(card){return _c('div',{key:card.id,staticClass:"card-test",style:(_vm.cardStyle(card)),attrs:{"draggable":_vm.editMode}},[_c(_vm.cardComponents[card.cardComponent],{tag:"component",attrs:{"edit-mode":_vm.editMode,"my-card":card,"dimensions":{
          x: card.rect.width * (_vm.cellSize + _vm.border) - _vm.border - 6,
          y: card.rect.height * (_vm.cellSize + _vm.border) - _vm.border - 6
        }},on:{"cardEmit":_vm.relayCardEmit,"saveSettings":function($event){return _vm.saveSettingsForCard(card, $event)},"removeCard":function($event){return _vm.yeet(card)}}})],1)}),0):_vm._e(),(_vm.editMode)?_c('svg',{staticClass:"dashboard-background",attrs:{"width":"100%","height":"100%"},on:{"dragover":function($event){return _vm.dragOver($event)}}},[_c('defs',[_c('pattern',{attrs:{"id":"cross","width":_vm.cellSize + _vm.border,"height":_vm.cellSize + _vm.border,"patternUnits":"userSpaceOnUse"}},[_c('line',{attrs:{"x1":(_vm.border / 2),"x2":(_vm.border / 2),"y1":(_vm.border / 2) - (_vm.crossSize/2),"y2":(_vm.border / 2) + (_vm.crossSize/2)}}),_c('line',{attrs:{"y1":(_vm.border / 2),"y2":(_vm.border / 2),"x1":(_vm.border / 2) - (_vm.crossSize / 2),"x2":(_vm.border / 2) + (_vm.crossSize / 2)}})])]),_c('rect',{attrs:{"width":"100%","height":"100%","fill":"url(#cross)"}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }