<template>
  <div class="container">
    <div>
      <span class="indiHolder">
        <div class="indicator">
          <div />
          <div />
          <div />
          <div />
        </div>
        <div class="shadow">
          <div />
          <div />
          <div />
          <div />
        </div>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadingScreen'
}
</script>
<style lang="scss" scoped>
@import '@/common/style/style.scss';

.container {
  min-width: 100%;
  min-height: 100%;
  right: 0;
  top: -20;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  #loadText {
    z-index: 102;
    font-weight: 800;
    text-align: center;
    font-size: rem(24);
    color: $disabledBorderColor;
  }
}

.indiHolder {
  width: 100%;
  display: block;
  height: rem(100);
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.8;
  position: absolute;
  background-color: $whitesmoke;
}

.indicator {

  div {
    width: rem(10);
    height: rem(10);
    top: 50%;
    z-index: 101;
    margin: rem(2);
    border-radius: 50%;
    position: relative;
    display: inline-block;

    &:nth-of-type(1) { animation: slide 1s ease-in-out infinite; }

    &:nth-of-type(2) { animation: slide 1s -.1s ease-in-out infinite; }

    &:nth-of-type(3) { animation: slide 1s -.2s ease-in-out infinite; }

    &:nth-of-type(4) { animation: slide 1s -.3s ease-in-out infinite; }
  }
}
.shadow {

  div {
    width: rem(10);
    height: rem(5);
    z-index: 100;
    top: rem(40);
    margin: rem(2);
    border-radius: 50%;
    position: relative;
    display: inline-block;

    &:nth-of-type(1) { animation: fade 1s ease-in-out infinite; }

    &:nth-of-type(2) { animation: fade 1s -.1s ease-in-out infinite; }

    &:nth-of-type(3) { animation: fade 1s -.2s ease-in-out infinite; }

    &:nth-of-type(4) { animation: fade 1s -.3s ease-in-out infinite; }
  }
}

@keyframes slide {

  0% {
    top: 0;
    background-color: $slide-light;
  }

  50% {
    top: rem(50);
    background-color: $slide-dark;
  }

  100% {
    top: 0;
    background-color: $slide-light;
  }
}

@keyframes fade {

  0% {
    width: rem(7);
    height: rem(5);
    margin-left: rem(5);
    background-color: $infoGray;
  }

  50% {
    width: rem(9);
    height: rem(7);
    margin-left: rem(3);
    background-color: $infoGray;
  }

  100% {
    width: rem(7);
    height: rem(5);
    margin-left: rem(5);
    background-color: $infoGray;
  }
}
</style>
