import { unionBy } from 'lodash'

import * as locationApi from '@/common/API/LocationApi'

export const actionTypes = {
  GET_LOCATIONSUMMARIES: 'LOCATION/GET_LOCATIONSUMMARIES',
  GET_LOCATIONS: 'LOCATION/GET_LOCATIONS',
  CLEAR_STORE: 'LOCATION/CLEAR_STORE'
}

export const mutationTypes = {
  SET_LOCATIONSUMMARIES: 'LOCATION/SET_LOCATIONSUMMARIES',
  SET_LOCATIONS: 'LOCATION/SET_LOCATIONS',
  CLEAR_STORE: 'LOCATION/CLEAR_STORE'
}

const state = {
  locations: [],
  locationSummaries: null
}

const getters = {
  locations: state => state.locations,
  locationSummaries: state => state.locationSummaries
}

const actions = {
  [actionTypes.GET_LOCATIONS] ({ commit }) {
    return locationApi.getAllLocations()
      .then(result => {
        commit(mutationTypes.SET_LOCATIONS, result)
        return result
      })
  },

  [actionTypes.GET_LOCATIONSUMMARIES] ({ commit }, searchParameter) {
    return locationApi.getLocationSummaries(searchParameter)
      .then(result => {
        commit(mutationTypes.SET_LOCATIONSUMMARIES, result)
      })
  },

  [actionTypes.CLEAR_STORE] ({ commit }) {
    commit(mutationTypes.CLEAR_STORE)
  }
}

const mutations = {
  [mutationTypes.SET_LOCATIONS] (state, result) {
    state.locations = unionBy(result, state.locations, 'Id')
  },

  [mutationTypes.SET_LOCATIONSUMMARIES] (state, result) {
    state.locationSummaries = result
  },

  [mutationTypes.CLEAR_STORE] (state) {
    state.locations = []
    state.locationSummaries = null
  }
}

export const storeModule = {
  state,
  getters,
  actions,
  mutations
}
