import { getBrandcheckApiEndpoint } from '@/common/proxyConfig'
import { getJson, getFile } from './../../common/API/BaseApi.js'

const baseRoute = 'api/analysis'

export const getAllDefects = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/alldefects` })
}

export const getDefects = function (scanId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/defects/${scanId}` })
}

export const getPinInstance = function (pinInstanceId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/pininstance/${pinInstanceId}` })
}

export const getPinInstances = function (scanId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/pininstances/${scanId}` })
}

export const getQuestionsAndAnswers = function (scanId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/questionandanswers/${scanId}` })
}

export const getOperationalGoals = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/alloperationalgoals` })
}

export const getOperationalGoalProgress = function (scanId, goalId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/goal/operational/${scanId}/${goalId}` })
}

export const getCategoryProgress = (scanId, goalId) =>
  getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/goal/defects/${scanId}/${goalId}/` })

export const getAmbitionProgress = function (scanId, goalId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/goal/ambition/${scanId}/${goalId}` })
}

export const getTemporaryResultsForAmbitionGoals = (scanId, ambitionGoalId) =>
  getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/temporaryresults/${scanId}/${ambitionGoalId}` })

export const getAmbitionDefects = function (scanId, goalId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/goal/defects/${scanId}/${goalId}` })
}

export const getMediaForScan = (mediaId) => {
  return getFile({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/defects/media/download/${mediaId}` })
}

export const getPinMediaLink = function ({ mediaId }) {
  return `${getBrandcheckApiEndpoint()}/${baseRoute}/pins/media/download/${mediaId}`
}

export const getDrawingForScan = (drawingId) => {
  return getFile({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/drawing/${drawingId}` })
}

export const getProgressSummaryForLocation = function (locationId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/progresssummary/location/${locationId}` })
}

export const getProgressSummaryForEntity = function (entityId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/progresssummary/entity/${entityId}` })
}

export const getEntityProgressItems = function (searchParameter = '') {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/progress/all?searchparameter=${encodeURIComponent(searchParameter)}` })
}

export const getLocationProgressSummaries = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/progresssummary/location/all` })
}

export const getOrganisationProgress = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/progress/organisation` })
}

export const getAllEntityProgressSummaries = function () {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/progresssummary/all` })
}

export const getEntityProgressSummariesByLocationId = function (locationId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/progresssummary/entity/bylocationid/${locationId}` })
}

export const getEntityProgressSummariesByParentId = function (parentId) {
  return getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/progresssummary/entity/byparentid/${parentId}` })
}

export const getAggregatedEntityProgressDetails = entityId =>
  getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/progress/${entityId}` })

export const getDefectsPinInstances = scanId =>
  getJson({ url: `${getBrandcheckApiEndpoint()}/${baseRoute}/pininstances/${scanId}` })
