<template>
  <div
    class="pagination"
  >
    <div
      :class="{ 'pagination__icon--disabled': value === 1 }"
      class="pagination__icon pagination__icon--left"
      @click="previousPage"
    >
      <p4a-icon
        :icon="'chevron'"
      />
    </div>
    <div class="pagination__tray">
      <div
        v-for="page in pages"
        :key="page.key"
        :class="{'pagination__page--active': value === page.pageNumber, 'pagination__page--ellipsis': page.ellipsis}"
        class="pagination__page"
        @click="setActivePage(page.pageNumber)"
      >
        {{ page.pageNumber }}
      </div>
    </div>
    <div
      :class="{ 'pagination__icon--disabled': lastPage }"
      class="pagination__icon"
      @click="nextPage"
    >
      <p4a-icon
        :icon="'chevron'"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Pagination',
  props: {
    value: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10
    },
    range: {
      type: Number,
      default: 1
    }
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.items.length / this.pageSize)
    },

    pages () {
      const array = []

      for (let i = 1; i <= this.numberOfPages; i++) {
        // Add number in DOM if in range
        if (this.inRange(i, this.value - 1, this.value + 1)) {
          array.push({
            pageNumber: i,
            key: i,
            active: i === this.value // je logica voor je pagina
          })
        } else if ((this.value === this.range || this.value === this.numberOfPages) && this.inRange(i, this.value - (this.range + 1), this.value + (this.range + 1))) {
          array.push({
            pageNumber: i,
            key: i,
            active: i === this.value
          })
        } else if (i === 1 || i === this.numberOfPages) { // Else if add number if first
          if (!this.inRange(i, this.value, this.value + 2) && i === this.numberOfPages) { // Add ellipsis if number between range and last number
            array.push({
              pageNumber: '...',
              key: 'ellipses_before_' + i,
              ellipsis: true
            })
          }

          array.push({
            pageNumber: i,
            key: i,
            active: i === this.value
          })

          if (!this.inRange(i, this.value - 2, this.value) && i === 1) { // Add ellipsis if number between range and first number
            array.push({
              pageNumber: '...',
              key: 'ellipses_after_' + i,
              ellipsis: true
            })
          }
        }
      }

      return array
    },

    lastPage () {
      return this.value === this.numberOfPages
    }
  },
  methods: {
    inRange (x, min, max) {
      return ((x - min) * (x - max) <= 0)
    },
    setActivePage (i) {
      if (typeof i === 'number') {
        this.$emit('input', i)
      }
    },
    nextPage () {
      if (!this.lastPage) {
        this.$emit('input', this.value + 1)
      }
    },
    previousPage () {
      if (this.value > 1) {
        this.$emit('input', this.value - 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.pagination {
  height: rem(38);
  float: right;
  line-height: rem(38);

  &__icon {
    width: rem(38);
    height: rem(38);
    float: left;
    cursor: pointer;
    font-size: rem(7);
    position: relative;
    text-align: center;

    &:hover {
      cursor: pointer;
      color: $headerColor;
    }

    &--left { transform: rotate(180deg); }

    &--disabled {
      cursor: default;
      color: $disabledColor;

      &:hover {
        cursor: default;
        color: $disabledColor;
      }
    }
  }

  &__tray {
    height: rem(38);
    float: left;
    position: relative;
  }

  &__page {
    width: rem(38);
    height: rem(38);
    float: left;
    font-size: rem(13);
    text-align: center;
    color: $defaultFontColor;

    &:hover {
      cursor: pointer;
      color: $headerColor;
    }

    &--active {
      color: $pendingBlue;
      border-bottom: rem(2) solid $pendingBlue;

      &:hover { color: $pendingBlue; }
    }

    &--ellipsis {
      cursor: default;

      &:hover { cursor: default; }
    }
  }
}
</style>
