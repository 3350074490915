<template>
  <div>
    <div class="input-wrapper">
      <p4a-button
        class="account__button"
        :product="'tbq'"
        :size="'large'"
        :full-width="true"
        :text="$t('emailSent.resendLink')"
        @click="resendLink"
      />
      <p4a-button
        :text="$t('general.back')"
        :size="'large'"
        :full-width="true"
        class="account__button"
        @click="$router.push({name: 'login'})"
      />
    </div>
  </div>
</template>

<script>
import * as accountApi from '@/common/API/AccountApi'

export default {
  data () {
    return {
      redelivered: false,
      sliderProperties: {
        sliderImagePath: '',
        sliderImageStyle: 'email-sent',
        sliderTitle: this.$t('emailSent.title'),
        sliderText: ''
      }
    }
  },
  mounted () {
    const self = this
    self.getMessage()
    self.$emit('loadForm', self.sliderProperties)
  },
  methods: {
    resendLink () {
      const self = this
      const route = self.$route.name
      const email = self.$route.query.to
      if (route === 'passwordemailsent') {
        accountApi.passwordRequest(email).then(() => {
          this.$router.push({ name: 'passwordemailsent', query: { to: email } })
        })
      }
    },
    getMessage () {
      const self = this
      const route = self.$route.name
      if (this.redelivered) {
        self.sliderProperties.sliderText = self.$t('emailSent.redelivered')
        self.sliderProperties.sliderImagePath = '/asset/wrapper/doubleLogin.png'
      } else {
        if (route === 'signupemailsent') {
          self.sliderProperties.sliderText = self.$t('emailSent.signUpMessage')
          self.sliderProperties.sliderImagePath = '/asset/wrapper/doubleLogin.png'
        } else if (route === 'passwordemailsent') {
          self.sliderProperties.sliderText = self.$t('emailSent.forgotPasswordMessage')
          self.sliderProperties.sliderImagePath = '/asset/wrapper/forgotPassword.png'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';
@import '@/common/style/AccountForm.scss';
</style>
