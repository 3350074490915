<template lang="html">
  <div class="slider">
    <carousel
      :autoplay="true"
      :items="1"
      :nav="false"
      :loop="true"
    >
      <div
        v-for="slide in slides"
        :key="slide.title"
        class="slide"
      >
        <img
          class="slide-image"
          :src="slide.image"
        >
        <div class="slide-cta">
          <h2 class="slide-cta__title">
            {{ slide.title }}
          </h2>
          <h3 class="slide-cta__description">
            {{ slide.description }}
          </h3>
        </div>
      </div>
    </carousel>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  name: 'LoginCarousel',
  components: { carousel },
  data () {
    return {
      slides: [
        {
          title: 'Scans',
          description: 'Bespaar kosten en moeite. Controleer dat wat nodig is en zie niks over het hoofd.',
          image: '/asset/wrapper/login/scans.png'
        },
        {
          title: '(Externe) Gebruikers',
          description: 'Verdeel scans en taken over medewerkers. Creëer bewustwording.',
          image: '/asset/wrapper/login/gebruikers.png'
        },
        {
          title: 'Tekeningen',
          description: 'Door pins te plaatsen, kan je altijd iets snel herkennen en terug vinden.',
          image: '/asset/wrapper/login/tekeningen.png'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@/common/style/style.scss';

.slider {
  padding: 0;
  position: relative;
  margin-bottom: 25px;

  .slide {

    &-image {
      height: 100%;
      width: auto;
    }

    &-controls {
      width: 350px;
      height: 12px;
      margin-bottom: 13px;
    }

    &-cta {

      &__title {
        height: auto;
        font-size: 20px;
        font-weight: 500;
        padding-top: 25px;
        margin-bottom: 10px;
      }

      &__description {
        color: $defaultFontColor;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 25px;
      }
    }
  }
}

.owl-theme {

  .owl-dots {

    .owl-dot {

      span { background: $infoGray; }

      &:hover {

        span { background: $defaultFontColor; }
      }

      &.active {

        span { background: $defaultFontColor; }
      }
    }
  }
}
</style>
