import { postJson, getJson, deleteJson, putJson } from './BaseApi.js'

const baseRoute = '/api/entity'

export const createEntities = function (newEntities) {
  return postJson({ url: `${baseRoute}`, data: newEntities })
}

export const getAllMissingSettings = function () {
  return getJson({ url: `${baseRoute}/missingsettings/all` })
}

export const updateEntities = function (updatedEntities) {
  return putJson({ url: `${baseRoute}/edit`, data: updatedEntities })
}

export const getEntityPropertyValues = function (entityId) {
  return getJson({ url: `${baseRoute}/propertyvalues/${entityId}` })
}

export const getEntitySettingValues = function (entityId) {
  return getJson({ url: `${baseRoute}/settingsvalues/${entityId}` })
}

export const deleteEntity = function (entityId) {
  return deleteJson({ url: `${baseRoute}/${entityId}` })
}

export const getEntityAncestry = function (entityId) {
  return getJson({ url: `${baseRoute}/ancestry/${entityId}` })
}

export const getAllEntityProperties = function () {
  return getJson({ url: `${baseRoute}/property/all` })
}

export const createEntityPropertyValues = function (entityPropertyValues) {
  return postJson({ url: `${baseRoute}/entitypropertyvalues/create`, data: entityPropertyValues })
}

export const getAllEntitySettings = function () {
  return getJson({ url: `${baseRoute}/settings/all` })
}

export const createEntitySettingValues = function (entitySettingValues) {
  return postJson({ url: `${baseRoute}/settingsvalues/create`, data: entitySettingValues })
}
export const getAllEntityTypes = function () {
  return getJson({ url: `${baseRoute}/type/all` })
}

export const getEntitiesByParent = function (parentId) {
  return getJson({ url: `${baseRoute}/all?parentid=${parentId}` })
}

export const getEntitiesByLocation = function (locationId) {
  return getJson({ url: `${baseRoute}/bylocation/${locationId}` })
}

export const getAllEntitySettingOptions = function () {
  return getJson({ url: `${baseRoute}/settingoptions/all` })
}

export const getAllBuildings = function () {
  return getJson({ url: `${baseRoute}/buildings` })
}

export const getDepartmentsByAncestorId = function (ancestorId) {
  return getJson({ url: `${baseRoute}/departments/${ancestorId}` })
}

export const getResidencesByAncestorId = ancestorId =>
  getJson({ url: baseRoute + '/residences/' + ancestorId })

export const getBuildingPartByAncestorId = ancestorId =>
  getJson({ url: baseRoute + '/buildingparts/' + ancestorId })

export const getEntity = entityId =>
  getJson({ url: baseRoute + '/' + entityId })
