<template>
  <span
    class="stringItem regular-13"
    :style="styleOptions + clickStyle"
    :title="formattedItem"
    @click="emitId"
    v-text="formattedItem"
  />
</template>

<script>
export default {
  props: {
    item: {
      type: [Object, String, Number],
      required: true
    },
    formatType: {
      type: String,
      default: ''
    },
    associatedId: {
      type: String,
      default: null
    },
    total: {
      type: Number,
      default: 0
    }
  },

  computed: {
    clickStyle () {
      if (this.associatedId && this.formatType !== 'scanStateDeletable') {
        return 'cursor: pointer;'
      } else {
        return null
      }
    },
    formattedItem () {
      switch (this.formatType) {
        case 'countdown':
          if (this.item <= this.total) {
            return this.item
          } else {
            return this.item
          }

        case 'percentage':
          return `${this.item}%`

        case 'scansUserTotal':
          return `  ${this.item.ScansComplete} / ${this.item.ScansTotal}`

        case 'entityType':
          switch (this.item) {
            case 0:
              return this.$t('Building')
            case 1:
              return this.$t('BuildingPart')
            case 2:
              return this.$t('Department')
            default:
              return ''
          }

        case 'scanState':
          switch (this.item) {
            case 1:
              return this.$t('pending')
            case 2:
              return this.$t('finished')
            case 3:
              return this.$t('expired')
            case 4:
              return this.$t('archived')
            case 5:
              return this.$t('deleted')
            default:
              return ''
          }

        case 'scanStateDeletable':
          switch (this.item) {
            case 1:
              return this.$t('pending')
            case 2:
              return this.$t('finished')
            case 3:
              return this.$t('expired')
            case 4:
              return this.$t('archived')
            case 5:
              return this.$t('deleted')
            default:
              return ''
          }

        case 'date':
          if (this.item !== '-') {
            const year = this.item.substring(0, 4)
            const month = this.item.substring(5, 7)
            const day = this.item.substring(8, 10)
            return `${day}-${month}-${year}`
          } else return '-'

        default:
          return this.item ? this.item : ''
      }
    },

    /**
     * styleOptions() is an independent computed property
     * computed properties are according to the vue guidelines
     * not allowed to have side effects. This keeps the code easier to read.
     */
    styleOptions () {
      switch (this.formatType) {
        case 'countdown':
          if (this.item <= this.total) {
            return 'color: red; font-weight: 400;'
          } else {
            return ''
          }

        case 'scansUserTotal':
          return 'text-align: center'

        case 'scanState':
          switch (this.item) {
            case 1:
              return 'color: grey; font-weight: 400;'
            case 2:
              return 'color: #88C440; font-weight: 400;'
            case 3:
              return 'color: #EF9825; font-weight: 400;'
            case 4:
              return 'color: #AAAAAA; font-weight: 400;'
            case 5:
              return 'color: #AAAAAA; font-weight: 400;'
            default:
              return ''
          }

        case 'scanStateDeletable':
          switch (this.item) {
            case 1:
              return 'color: grey; font-weight: 400;'
            case 2:
              return 'color: #88C440; font-weight: 400;'
            case 3:
              return 'color: #EF9825; font-weight: 400;'
            case 4:
              return 'color: #AAAAAA; font-weight: 400;'
            case 5:
              return 'color: #AAAAAA; font-weight: 400;'
            default:
              return ''
          }

        default:
          return ''
      }
    }
  },
  methods: {
    emitId () {
      this.$parent.$emit('itemClick', this.associatedId)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.stringItem {
  width: 100%;
  min-height: rem(15);
  margin: 0;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
