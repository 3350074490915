import uuid from 'uuid-v4'
import { getOntruimcheckApiEndpoint } from '@/common/proxyConfig'
// import { module as ApiStore } from '../Store/Modules/apiErrorModule'

// Get the appId from localStorage.
let appId = JSON.parse(window.localStorage.getItem('appId') || 'null')
// Generate and persist a new one?
if (appId === null) {
  appId = uuid()
  window.localStorage.setItem('appId', JSON.stringify(appId))
}
// Set the cookie.
document.cookie = 'appId=' + appId + ';path=/'

const createApiError = response => {
  return {
    url: response.url,
    message: response.statusText,
    statusCode: response.status
  }
}

const handleError = statusCode => {
  // ApiStore.dispatch(ApiStore.actions.ADD_API_ERROR, { statusCode })
}

export const getJson = function ({ url }) {
  return new Promise((resolve, reject) => {
    window.fetch(getOntruimcheckApiEndpoint() + url, {
      credentials: 'same-origin',
      headers: getHeaders()
    }).then(response => {
      // Check if the response was ok.
      if (response.ok) {
        // Parse as json.
        return response.json()
      } else {
        // Request failed.
        throw createApiError(response)
      }
    }).then(data => {
      // Done
      resolve(data)
    }).catch(exception => {
      handleError(exception.statusCode)
      reject(exception)
    })
  })
}

export const getFile = function ({ url }) {
  return new Promise((resolve, reject) => {
    window.fetch(getOntruimcheckApiEndpoint() + url, {
      credentials: 'same-origin',
      headers: getHeaders()
    }).then(response => {
      // Check if the response was ok.
      if (response.ok) {
        return response.blob()
      } else {
        // Request failed.
        throw createApiError(response)
      }
    }).then(data => {
      // Done
      resolve(data)
    }).catch(exception => {
      handleError(exception.statusCode)
      reject(exception)
    })
  })
}

export const postJson = function ({ url, data }) {
  return new Promise((resolve, reject) => {
    window.fetch(getOntruimcheckApiEndpoint() + url, {
      method: 'post',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'same-origin'
    }).then(response => {
      // Check if the response was ok.
      if (response.ok) {
        if (response.status !== 204) {
          return response
        }
      } else {
        // Request failed.
        throw createApiError(response)
      }
    }).then(data => {
      // Done
      resolve(data)
    }).catch(exception => {
      handleError(exception.statusCode)
      reject(exception)
    })
  })
}

export const postGetJson = function ({ url, data }) {
  return new Promise((resolve, reject) => {
    window.fetch(getOntruimcheckApiEndpoint() + url, {
      method: 'post',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'same-origin'
    }).then(response => {
      // Check if the response was ok.
      if (response.ok) {
        if (response.status !== 204) {
          return response.json()
        }
      } else {
        // Request failed.
        throw createApiError(response)
      }
    }).then(data => {
      // Done
      resolve(data)
    }).catch(exception => {
      handleError(exception.statusCode)
      reject(exception)
    })
  })
}

export const putJson = function ({ url, data }) {
  return new Promise((resolve, reject) => {
    window.fetch(getOntruimcheckApiEndpoint() + url, {
      method: 'put',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'same-origin'
    }).then(response => {
      // Check if the response was ok.
      if (response.ok) {
        if (response.status !== 204) {
          // Parse as json.
          return response.json()
        }
      } else {
        // Request failed.
        throw createApiError(response)
      }
    }).then(data => {
      // Done
      resolve(data)
    }).catch(exception => {
      handleError(exception.statusCode)
      reject(exception)
    })
  })
}

export const deleteJson = function ({ url, data }) {
  return new Promise((resolve, reject) => {
    window.fetch(getOntruimcheckApiEndpoint() + url, {
      method: 'delete',
      headers: getHeaders(),
      body: JSON.stringify(data),
      credentials: 'same-origin'
    }).then(response => {
      if (response.ok) {
        if (response.status !== 204) {
          return response.json()
        }
      } else {
        throw createApiError(response)
      }
    }).then(data => {
      resolve(data)
    }).catch(exception => {
      handleError(exception.statusCode)
      reject(exception)
    })
  })
}

export const getXMLHttpRequest = function (method, uri) {
  // Create a request.
  const request = new window.XMLHttpRequest()
  // Open the request.
  request.open(method, getOntruimcheckApiEndpoint() + uri, true)
  // Set required headers.
  request.setRequestHeader('X-AppId', appId)

  const authToken = window.localStorage.getItem('auth-token')
  if (authToken !== null) {
    request.setRequestHeader('Authorization', 'Bearer ' + authToken)
  }

  // Return the request.
  return request
}

function getHeaders () {
  var result = {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-AppId': appId
  }

  const authToken = window.localStorage.getItem('auth-token')
  if (authToken !== null) {
    result.Authorization = 'Bearer ' + authToken
  }

  return result
}
