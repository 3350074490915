<template>
  <div
    class="gridContainer"
    :class="[{gridContainerNoPadding: !gridContainerPadding}]"
  >
    <div
      v-if="showHeader"
      class="gridHeader"
    >
      <h2 v-if="title">
        {{ title }}
      </h2>
      <slot />
      <div
        v-if="showPaginator"
        class="paginationContainer"
      >
        <pagination
          v-if="!loading"
          v-model="pageNumber"
          :items="items"
          :range="1"
          :page-size="pageSize"
        />
      </div>
    </div>

    <div
      v-if="!loading"
      class="tableContainer scrollContainer"
    >
      <div
        v-for="(table, tableIndex) in shownTablesPerGrid"
        :key="tableIndex"
        :class="{ scrollable: !showPaginator }"
      >
        <table
          class="sortable__table"
        >
          <thead>
            <tr>
              <th
                v-for="(column, columnIndex) in columns"
                :key="columnIndex"
                :style="column.widthPercentage ? { 'width' : column.widthPercentage + '%' } : columnsWidth"
              >
                <div
                  v-if="column.sortBy"
                  class="column-name"
                  @click="sort(column)"
                >
                  <span>{{ column.name }}</span>
                  <p4a-icon
                    class="sorting__icon"
                    :color="'#58585B'"
                    :icon="'select-small'"
                  />
                </div>
                <div
                  v-else
                  class="column-name"
                  v-text="column.name"
                />
              </th>
            </tr>
          </thead>
          <tbody
            v-if="showPaginator"
            class="gridBody"
          >
            <tr
              v-for="(item, index) in pages[table - 1]"
              :key="index"
              :class="{disabled: item.disabled}"
            >
              <td
                v-for="(column, columnIndex) in columns"
                :key="columnIndex"
                :style="column.widthPercentage ? { 'width' : column.widthPercentage + '%' } : columnsWidth"
              >
                <component
                  :is="modules[column.type]"
                  :item="item[column.key]"
                  :format-type="column.formatType"
                  :total="column.total"
                  :associated-id="item[column.associatedId]"
                />
              </td>
            </tr>
          </tbody>
          <tbody
            v-else
            class="gridBody"
          >
            <tr
              v-for="(item, index) in sortedItems"
              :key="index"
              :class="{ disabled: item.disabled }"
            >
              <td
                v-for="(column, columnIndex) in columns"
                :key="columnIndex"
                :style="column.widthPercentage ? { 'width' : column.widthPercentage + '%' } : columnsWidth"
              >
                <component
                  :is="modules[column.type]"
                  :item="item[column.key]"
                  :format-type="column.formatType"
                  :total="column.total"
                  :associated-id="item[column.associatedId]"
                  @checkEvent="check($event)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="emptyStateMessage.showEmptyStateMessage">
      <div class="message first">
        <div class="es-header">
          {{ emptyStateMessage.header }}
        </div>
      </div>
      <div class="message">
        <div class="es-body">
          {{ emptyStateMessage.body }}
        </div>
      </div>
    </div>
    <loading-screen v-show="loading" />
  </div>
</template>

<script>
import * as _ from 'lodash'
import LoadingScreen from '@/brandcheck/components/Modules/LoadingScreen'
import Pagination from '@/common/Components/Pagination/Pagination'

export default {
  components: {
    'loading-screen': LoadingScreen,
    pagination: Pagination
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    modules: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    pageSize: {
      type: Number,
      default: 10
    },
    showHeader: {
      type: Boolean,
      default: false
    },
    hideShadow: {
      type: Boolean,
      default: false
    },
    tablesPerGrid: {
      type: Number,
      default: 1
    },
    gridContainerPadding: {
      type: Boolean,
      default: true
    },
    showPaginator: {
      type: Boolean,
      default: true
    },
    hasTopBorder: {
      type: Boolean,
      default: true
    },
    hasBottomBorder: {
      type: Boolean,
      default: true
    },
    emptyStateMessage: {
      type: Object,
      default: () => {
        return {
          showEmptyStateMessage: false,
          message: ''
        }
      }
    }
  },
  data () {
    return {
      sortBy: '',
      sortType: 'string',
      sortDirection: '',
      pageNumber: 1
    }
  },
  computed: {
    sortedItems () {
      if (this.sortBy !== '') {
        switch (this.sortType) {
          case 'date':
            return _.orderBy(this.items, (i) => new Date(i[this.sortBy]), [this.sortDirection])
          case 'index':
            return _.orderBy(this.items, (i) => i[this.sortBy].index, [this.sortDirection])
          default:
            return _.orderBy(this.items, (i) => _.toLower(i[this.sortBy]), [this.sortDirection])
        }
      } else {
        return this.items
      }
    },
    lastPageNumber () {
      const itemsLength = this.items.length
      const pageLength = (this.pageSize * this.tablesPerGrid)
      return Math.ceil(itemsLength / pageLength)
    },
    lastPage () {
      return (this.pageNumber * this.pageSize * this.tablesPerGrid) >= this.items.length
    },
    pages () {
      const pagesArray = []
      let tableIndex = 1
      let initialIndex = (this.pageNumber - 1) * this.pageSize * this.tablesPerGrid
      let finalIndex = initialIndex + this.pageSize
      while (tableIndex <= this.tablesPerGrid) {
        pagesArray.push(this.sortedItems.filter((row, index) => {
          if (index >= initialIndex && index < finalIndex) return true
        }))
        initialIndex = finalIndex
        finalIndex = finalIndex + this.pageSize
        tableIndex++
      }
      return pagesArray
    },
    columnsWidth () {
      return {
        width: 100 / this.columns.length + '%'
      }
    },
    shownTablesPerGrid () {
      let tablesArray = []
      for (let table = 1; table <= this.tablesPerGrid; table++) {
        if (this.pages[table - 1].length > 0) {
          tablesArray.push(table)
        }
      }
      if (tablesArray.length === 0) {
        tablesArray = [{}]
      }
      return tablesArray
    }
  },
  watch: {
    lastPageNumber: {
      handler: function (lastPageNumber) {
        if (lastPageNumber > 0 && this.pageNumber > lastPageNumber) {
          this.pageNumber = lastPageNumber
        }
      }
    }
  },
  mounted () {
    this.$on('itemClick', function (id) { this.$parent.$emit('itemClick', id) })
    this.$on('mediaIconClick', id => { this.$parent.$emit('mediaIconClick', id) })
  },
  methods: {
    check (id) {
      this.$parent.$emit('checkboxClick', id)
    },
    sort (column) {
      this.sortType = column.formatType
      if (this.sortBy === column.sortBy) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortBy = column.sortBy
        this.sortDirection = 'asc'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.sorting {

  &__icon {
    margin-left: 5px;
    width: 6px;
    height: 30px;
  }
}

.sortable__table {
  table-layout: fixed;

  thead {

    th {
      border-top: rem(1) solid $defaultBorderColor;
      border-bottom: rem(1) solid $defaultBorderColor;
    }
  }

  th, td {

    &:first-child { padding-left: rem(10); }
  }
}

.gridContainer {
  width: 100%;
  background: $white;
  box-sizing: border-box;
  color: $defaultFontColor;

  &.gridContainerNoPadding { padding: 0; }
}

.gridBody {
  margin-bottom: rem(25);

  tr {
    transition-duration: 200ms;

    &.disabled {
      cursor: not-allowed;
      background: $disabledTableLine;
      color: $secondaryMediumGray;
    }

    &:hover { background: $navigationItemHover; }
  }
}

.scrollContainer {
  height: inherit;
  overflow: hidden;
}

.scrollable { overflow-y: scroll; }

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.tableContainer {
  width: 100%;
  display: flex;

  div {
    width: 100%;
    display: flex;

    &:nth-child(n+2) {
      padding-left: 6%;
      border-left: rem(1) solid $defaultBorderColor;
    }

    table {
      width: 100%;
      text-align: left;
      border-spacing: 0;

      thead {
        font-size: rem(13);
        padding-right: rem(10);
        height: rem(28);
        line-height: rem(28);
        transition-duration: 200ms;
      }

      tbody {

        tr {

          td {
            height: rem(25);
            vertical-align: middle;
            padding-right: rem(10);
          }
        }
      }
    }
  }
}

.gridHeader {
  width: 100%;
  display: inline-block;

  h2 {
    width: 50%;
    height: rem(50);
    margin: 0;
    padding: 0;
    float: left;
    font-weight: 300;
    text-align: left;
    font-size: rem(17);
    line-height: rem(50);
  }

  /deep/
  .paginationContainer {
    width: 50%!important;
    padding-top: rem(7);
  }
}

.gridHeader {

  .paginationContainer {
    width: 100%;
    margin-left: auto;

    .arrow {
      width: rem(25);
      height: rem(25);
      cursor: pointer;
      text-align: center;
      font-size: rem(20);
      margin-left: rem(5);
      line-height: rem(25);
      display: inline-block;
      vertical-align: middle;
      background-color: $arrow-background;

      &.disabled {
        opacity: 0.25;
        cursor: not-allowed;
      }
    }

    .Pagination { float: right; }
  }
}

.gridShadow { box-shadow: 0 0 rem(10) 0 rgba(50, 50, 50, 0.3); }

.column {

  &-name {
    min-height: rem(16);
    line-height: rem(30);
    vertical-align: middle;
  }

  &-sorting {
    width: rem(20);
    height: auto;
    vertical-align: middle;
  }
}

.innerPaddingLeft { padding-left: rem(15); }

.innerPaddingRight { padding-right: rem(15); }

.message {
  display: block;

  &.first { margin-top: rem(54); }

  .es-header {
    width: 100%;
    margin: 0;
    font-weight: 100;
    text-align: center;
    font-size: rem(17);
    line-height: rem(50);
    display: inline-block;
    vertical-align: middle;
  }

  .es-body {
    width: 100%;
    height: rem(50);
    font-weight: 100;
    text-align: center;
    font-size: rem(13);
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
