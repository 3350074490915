import Vue from 'vue'
import VueI18n from 'vue-i18n'

import wrapper from '@/common/Locales/nl'

Vue.use(VueI18n)

let currentApplication = null

let messages = { ...wrapper }

export const i18n = new VueI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages: messages
})

const setI18nLanguage = lang => {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export const loadLanguageAsync = (lang, application) => {
  if (currentApplication !== application) {
    const promise = import(/* webpackChunkName: "common-lang" */ '@/common/Locales/' + lang)

    return promise.then(msgs => {
      messages = { ...messages, ...msgs.default }
      i18n.setLocaleMessage(lang, messages)
      currentApplication = application
      return setI18nLanguage(lang)
    })
  }

  return Promise.resolve(lang)
}
