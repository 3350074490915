import { getJson, getXMLHttpRequest, getFile, postAndGetFileResponse, deleteJson, putJson } from './BaseApi.js'

const baseRoute = '/api/drawing'

export const getDrawing = drawingId =>
  getJson({ url: `${baseRoute}/${drawingId}` })

export const getAllDrawings = function (searchParameter = '') {
  return getJson({ url: `${baseRoute}/all?searchParameter=${searchParameter}` })
}

export const getDrawingByPinInstanceId = function (pinInstanceId) {
  return getJson({ url: `${baseRoute}/bypin/${pinInstanceId}` })
}

export const uploadDrawing = function (entityId, drawingId, fileName, userFile) {
  return UploadDrawingsEntity(userFile, `${baseRoute}/${entityId}/${drawingId}`, fileName)
}

export const getDrawingForScan = (drawingId) => {
  return getFile({ url: `${baseRoute}/download/${drawingId}` })
}

export const updateDrawing = function (drawing) {
  return putJson({ url: `${baseRoute}`, data: drawing })
}

export const deleteDrawing = (drawingId) => {
  return deleteJson({ url: `${baseRoute}/${drawingId}` })
}

export const getThumbnail = drawingId =>
  getFile({ url: `${baseRoute}/${drawingId}/thumbnail` })

export const getTileMapUrl = (drawingId, pageNumber) =>
  `${baseRoute}/${drawingId}/page/${pageNumber}/tile/{z}/{x}/{y}`

export const getPinInstances = drawingId =>
  getJson({ url: `${baseRoute}/pininstances/${drawingId}` })

export const getTemporaryThumbnail = userFile =>
  postAndGetFileResponse({ url: `${baseRoute}/temporary/thumbnail`, file: userFile }).then(toBase64)

export const prepareDrawingTiles = drawingId =>
  getJson({ url: `${baseRoute}/${drawingId}/temporary/tiles` })

function UploadDrawingsEntity (file, url, fileName) {
  return new Promise((resolve, reject) => {
    var formData = new FormData()
    formData.append('file', file)

    if (!fileName) {
      fileName = file.Name
    }

    formData.append('fileName', fileName)
    var xhr = getXMLHttpRequest('POST', url)

    // Subscribe to relevant requests
    xhr.addEventListener('load', function (event) {
      if (xhr.status === 200) {
        resolve()
      } else {
        reject(new Error())
      }
    })

    xhr.send(formData)
  })
}

const toBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      // console.log(reader.result.toString())
      resolve(reader.result.toString())
    }, false)
    reader.readAsDataURL(blob)
  })
}
