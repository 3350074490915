<template>
  <div>
    <form
      class="form"
      @keyup.enter="submit"
    >
      <p4a-input
        v-model="email"
        :placeholder="$t('login.email')"
        :left-icon="'user'"
        :full-width="true"
        :size="'large'"
        class="form__input"
      />
      <p4a-input
        v-model="password"
        :type="'password'"
        :placeholder="$t('login.password')"
        :left-icon="'locked'"
        :full-width="true"
        :size="'large'"
        class="form__input"
      />

      <p4a-button
        :text="$t('login.logIn')"
        :size="'large'"
        :product="'tbq'"
        :full-width="true"
        class="form__button"
        @click="submit"
      />

      <a
        href="#"
        class="form__link"
        @click="$router.push({name: 'requestpassword'})"
      >
        {{ $t('login.forgotPassword') }}
      </a>
    </form>
  </div>
</template>

<script>
import { actionTypes } from '@/common/store/sessionStore'

export default {
  data () {
    return {
      error: false,
      email: '',
      password: ''
    }
  },
  mounted () {
    const self = this
    self.$emit('loadForm', self.sliderProperties)
  },
  methods: {
    submit () {
      if (this.email === '' || this.password === '') {
        this.error = true
        this.$p4aToast.show(this.$t('toast.error'), this.$t('login.incorrect'), 'error', 4000)
      } else {
        this.$store.dispatch(actionTypes.AUTHENTICATE, {
          email: this.email.toLowerCase(),
          password: this.password
        }).then(() => {
          if (this.$store.getters.isAuthenticated) {
            this.errorMessage = ''
            this.$router.push('/dashboard')
          } else {
            this.error = true
            this.$p4aToast.show(this.$t('toast.error'), this.$t('login.incorrect'), 'error', 4000)
          }
        }).catch(() => {
          this.error = true
          this.$p4aToast.show(this.$t('toast.error'), this.$t('login.incorrect'), 'error', 4000)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/style/style.scss';

.form {

  &__input { margin-bottom: rem(15); }

  &__button {}

  &__link {
    width: 100%;
    height: rem(38);
    text-align: center;
    font-size: rem(13);
    margin-top: rem(30);
    line-height: rem(38);
    display: inline-block;
    text-decoration: none;
    color: $defaultFontColor;
  }
}
</style>
