<template>
  <base-card
    name="topMenu.ambitionAllBuildings"
    @removeCard="$emit('removeCard')"
  >
    <div class="card-main">
      <div class="sub-header">
        {{ $t('dashboard.allLocations') }}
      </div>

      <div v-if="loaded">
        <div v-if="ambitionGoals">
          <div class="completion__summary">
            {{ completionSummary }}
          </div>

          <div
            v-for="goal in ambitionGoals"
            :key="goal.name"
            class="chart"
          >
            <div class="chart__header">
              {{ goal.name }}
            </div>

            <div class="chart__fill">
              <div
                v-if="goal.notScanned > 0"
                class="chart__number"
              >
                <div class="chart__number__text">
                  {{ goal.notScanned }}
                </div>
              </div>

              <div
                class="chart__fill__color green"
                :style="{ width: calcRelativeBarWidth(goal.goalsReached, goal.total) }"
              >
                <div
                  v-if="goal.goalsReached > 0"
                  class="chart__number"
                >
                  <div class="chart__number__text">
                    {{ goal.goalsReached }}
                  </div>
                </div>
              </div>

              <div
                class="chart__fill__color red"
                :style="{ width: calcRelativeBarWidth(goal.goalsNotReached, goal.total) }"
              >
                <div
                  v-if="goal.goalsNotReached > 0"
                  class="chart__number"
                >
                  <div class="chart__number__text">
                    {{ goal.goalsNotReached }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="chart__legend">
            <div class="chart__legend--box green" />
            {{ $t('ambitionCard.passed') }}
            <div
              class="chart__legend--box red"
              :style="{ marginLeft: '25px' }"
            />
            {{ $t('ambitionCard.failed') }}
            <div
              class="chart__legend--box"
              :style="{ marginLeft: '25px' }"
            />
            {{ $t('ambitionCard.notScanned') }}
          </div>
        </div>

        <!--   EMPTY STATE   -->
        <div
          v-else
          class="empty__state"
        >
          Er zijn nog geen scans voltooid. Stel een scan op en zet deze uit om in kaart te brengen welke entiteiten aan het ambitieniveau voldoen.
          <btn
            class="empty__state--button"
            :small="true"
            @click="$router.push({ name: 'overviewscan' })"
          >
            {{ $t('menu.overviewscan') }}
          </btn>
        </div>
      </div>

      <!--  LOADING  -->
      <loading-screen v-if="!loaded" />
    </div>
  </base-card>
</template>
<script>
import * as _ from 'lodash'

import BaseCard from './BaseCard'

import LoadingScreen from '@/brandcheck/components/Modules/LoadingScreen'
import Button from '@/common/Components/Forms/Button'

import { getOrganisationProgress } from '@/brandcheck/API/AnalysisApi'
import { actionTypes as ambitionGoalActionTypes } from '@/brandcheck/store/ambitionGoalModule'

export default {
  components: {
    BaseCard,
    LoadingScreen,
    btn: Button
  },

  data () {
    return {
      goalIndex: null,
      loaded: false,
      organisationProgress: null
    }
  },
  computed: {
    goals () {
      return _.mapKeys(this.$store.getters.goals, function (goal) {
        return goal.Id
      })
    },
    ambitionGoals () {
      if (this.organisationProgress && this.goals) {
        const goals = []

        this.organisationProgress.AmbitionProgress.forEach(progress => {
          goals.push({
            name: this.goals[progress.GoalId].Name,
            goalsNotReached: progress.FailedEntities,
            goalsReached: progress.PassedEntities,
            notScanned: progress.TotalEntities - progress.PassedEntities - progress.FailedEntities,
            total: progress.TotalEntities
          })
        })

        return goals
      } else {
        return null
      }
    },
    completionSummary () {
      if (this.organisationProgress) {
        return `${this.organisationProgress.PassedEntities} / ${this.organisationProgress.TotalEntities} ${this.$t('compliance.completed')}`
      } else {
        return null
      }
    }
  },
  created () {
    const apiPromises = []
    const self = this

    apiPromises.push(this.$store.dispatch(ambitionGoalActionTypes.GET_ALL_GOALS))
    apiPromises.push(getOrganisationProgress().then(result => { self.organisationProgress = result }))
    Promise.all(apiPromises).then(() => {
      this.goalIndex = _.size(this.data) > 0 ? this.data.goalIndex : 0
      this.loaded = true
    })
  },
  methods: {
    calcRelativeBarWidth (fraction, total) {
      return (fraction / total * 498).toString() + 'px'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/style/style.scss';
@import '@/common/style/DashboardCard.scss';

.completion__summary {
  font-size: rem(13);
  margin-top: rem(20);
  line-height: rem(37);
}

.chart {
  &__header {
    font-weight: 100;
    color: $textColor;
    font-size: rem(13);
    line-height: rem(25);
  }

  &__fill {
    width: rem(498);
    height: rem(25);
    color: $darkBorder;
    margin-bottom: rem(25);
    background-color: $darkBorder;

    &__color {
      height: 100%;
      display: inline-block;
    }
  }

  &__number {
    width: inherit;
    height: rem(25);
    position: absolute;

    &__text {
      width: rem(25);
      right: 0;
      font-weight: 300;
      bottom: rem(-20);
      position: absolute;
      text-align: center;
      font-size: rem(13);
    }
  }

  &__legend {
    width: 100%;
    height: rem(25);
    font-weight: 100;
    font-size: rem(13);
    margin-top: rem(50);
    color: $defaultFontColor;

    &--box {
      width: rem(10);
      height: rem(10);
      margin-right: rem(5);
      display: inline-block;
      background-color: $darkBorder;
    }
  }
}

.green {
  color: $successful;
  background-color: $successful;
}

.red {
  color: $unsuccessful;
  background-color: $unsuccessful;
}

.empty__state {
  font-size: rem(13);
  padding: rem(25) rem(50);

  &--button { margin: rem(25) auto 0 auto; }
}

</style>
