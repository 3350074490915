import { unionBy } from 'lodash'

import { getDrawingForScan } from '@/brandcheck/API/AnalysisApi'
import { getThumbnail, prepareDrawingTiles } from '@/common/API/DrawingApi'

export const actionTypes = {
  GET_DRAWING_FOR_SCAN: 'GET_DRAWING_FOR_SCAN',
  GET_THUMBNAIL: 'GET_THUMBNAIL',
  PREPARE_TILES: 'PREPARE_TILES'
}

export const mutationTypes = {
  ADD_DRAWING: 'ADD_DRAWING',
  ADD_THUMBNAIL: 'ADD_THUMBNAIL'
}

const state = {
  drawings: [],
  thumbnails: []
}

const getters = {
  drawings: state => state.drawings,
  thumbnails: state => state.thumbnails
}

const actions = {
  [actionTypes.GET_DRAWING_FOR_SCAN] ({ commit }, drawingId) {
    return getDrawingForScan(drawingId)
      .then(toBase64)
      .then(drawingSrc => {
        const drawing = { Id: drawingId, Src: drawingSrc }

        commit(mutationTypes.ADD_DRAWING, drawing)

        return drawing
      }).catch(error => {
        if (error.statusCode !== 204) {
          throw error
        }
      })
  },

  [actionTypes.PREPARE_TILES] ({ commit }, drawingId) {
    return prepareDrawingTiles(drawingId)
  },

  [actionTypes.GET_THUMBNAIL] ({ commit }, drawingId) {
    return getThumbnail(drawingId)
      .then(toBase64)
      .then(thumbnailSrc => {
        const thumbnail = { Id: drawingId, Src: thumbnailSrc }

        commit(mutationTypes.ADD_THUMBNAIL, thumbnail)

        return thumbnail
      }).catch(error => {
        if (error.statusCode !== 204) {
          throw error
        }
      })
  }
}

const mutations = {
  [mutationTypes.ADD_DRAWING] (state, drawing) {
    state.drawings = unionBy([drawing], state.drawings, 'Id')
  },

  [mutationTypes.ADD_THUMBNAIL] (state, thumbnail) {
    state.thumbnails = unionBy([thumbnail], state.thumbnails, 'Id')
  }
}

export const toBase64 = blob => {
  return new Promise((resolve, reject) => {
    if (blob === null) {
      reject(new Error('Received null'))
    }

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      resolve(reader.result.toString())
    }, false)
    reader.readAsDataURL(blob)
  })
}

export const storeModule = {
  state,
  getters,
  actions,
  mutations
}
