import { postGetJson, postJson, getJson } from './BaseApi'

export const calculatePrimaryResult = function (wizardInput) {
  return postGetJson({
    url: '/api/result/calculate-results',
    data: wizardInput
  })
}

export const createSummary = function (resultData) {
  return postGetJson({
    url: '/api/result/summarize',
    data: resultData
  })
}

export const saveOntruimcheck = function (wizardInput) {
  return postJson({
    url: '/api/configuration/summarized-result',
    data: wizardInput
  })
}

export const getResultSummaryByEntityId = function (entityId) {
  return getJson({
    url: `/api/result/summarized-results/${entityId}`
  })
}

export const getResultSummariesByConfiguration = configurationId =>
  getJson({ url: '/api/result/summarized-results/configuration/' + configurationId })
