export default {
  acceptLimitedDeviations: 'Accepteer beperkte afwijkingen van de wetgeving.',
  acceptLimitedDeviationsIfQuestionAnswered: 'Accepteer beperkte afwijkingen indien de expert de vraag beantwoord met \'kleine afwijking\' of \'lage frequentie\'.',
  acceptLimitedDeviationsWithContribution: 'Accepteer beperkte afwijkingen met een kleine bijdrage aan het ambitieniveau.',
  acceptOnlyFewImportantDeviations: 'Accepteer alleen weinig belangrijke afwijkingen t.o.v. eisen van het Bouwbesluit.',
  acceptAlsoMinorDeviations: 'Accepteer ook minder belangrijke afwijkingen t.o.v. eisen van het Bouwbesluit.',
  add: 'Toevoegen',
  address: 'Adres',
  addressData: 'Adresgegevens',
  allLocations: 'Alle locaties',
  allBuildings: 'Alle gebouwen',
  ambitionLevel: 'Ambitieniveau',
  ambitionCard: {
    passed: 'Behaald',
    failed: 'Niet behaald',
    notScanned: 'Niet gescand'
  },
  ambitionLevelAchieved: 'Ambitieniveau behaald',
  ambitionLevelNotAchieved: 'Ambitieniveau niet behaald',
  analysis: 'Analyses',
  analysisDetail: {
    scans: {
      building: 'Gebouw',
      level2: 'Gebouwdeel/Woning',
      level3: 'Afdeling'
    },
    completionOfScans: 'Voltooien van scans',
    defectsFound: 'Geen tekortkomingen | 1 tekortkoming gevonden | {count} tekortkomingen gevonden.',
    selectBuildingPart: 'Selecteer een gebouwdeel',
    selectDepartment: 'Selecteer een afdeling',
    scanDetailHeader: 'Scan van {entityName} voor {goal}',
    performedOn: 'Scan uitgevoerd op',
    performedBy: 'Scan uitgevoerd door',
    acceptedDeviation: 'Geaccepteerde afwijkende klasse',
    operationalGoalProgress: 'Voortgang operationele doelen',
    defects: 'Tekortkomingen',
    noScanResultError: 'Deze scan is niet voltooid en kan daarom geen resultaten tonen.',
    noReportPosible: 'Er zijn geen voltooide scans waarvoor een raport gegeneerd kan worden.',
    scanExpiredError: 'Deze de resultaten van deze scan zijn verlopen en tellen niet mee voor deze analyse.',
    export: 'Genereer rapportage',
    datedScanError: 'Deze scan is meer dan een jaar oud. Het kan nodig zijn een nieuwe scan uit te voeren.'
  },
  analysisOverview: {
    viewResults: 'Bekijk de resultaten',
    containsDatedScan: 'Deze resultaten komen voort uit een of meer verouderde scans'
  },
  answer: 'Antwoord',
  archived: 'Gearchiveerd',
  archiveCheck: 'Check archiveren',
  archivedChecks: 'Gearchiveerde checks',
  archiveScan: 'Scan archiveren',
  archiveScanError: 'Archiveren mislukt',
  archiveScanSuccess: 'Scan gearchiveerd',
  assignTo: 'Toewijzen aan',
  building: 'Gebouw',
  buildingExtramural: 'Extramuraal',
  buildingIntramural: 'Intramuraal',
  buildingUtility: 'Utiliteitsbouw',
  buildingMissingSettings: 'heeft nog niet ingevulde instellingen. Configureer eerst uw gebouw voordat u een scan aanmaakt.',
  buildingPart: 'Gebouwdeel',
  buildingPartUtility: 'Gebouwdeel',
  buildingPartMissingSettings: 'heeft nog niet ingevulde instellingen. Configureer eerst uw gebouwdeel voordat u een scan aanmaakt.',
  buildings: 'Gebouwen',
  buildingsOverview: 'Overzicht gebouwen',
  by: 'Door',
  cancel: 'Annuleren',
  cancelChanges: 'Veranderingen annuleren',
  category: 'Categorie',
  changePassword: 'Wachtwoord wijzigen',
  changePasswordFailed: 'Wachtwoord wijzigingen is niet gelukt. Probeer het opnieuw',
  changePasswordSucceded: 'Wachtwoord is succesvol gewijzigd',
  checkYourInput: 'Controleer uw invoer',
  chooseAFunction: 'kies een functie',
  class: 'Klasse',
  clientInCaseOfFire: 'Voor “Gedrag en zelfredzaamheid van de cliënt bij brand”',
  clientRiskCausingFire: 'Kans op veroorzaken brand door de cliënt',
  comments: 'Opmerkingen',
  completeBefore: 'Afronden voor',
  company: 'Bedrijf',
  complete: 'Voltooid/totaal',
  confirmPassword: 'Wachtwoord bevestigen',
  consciouslyUnsafeBehavior: '(On-)bewust onveilig gedrag',
  connectedTo: 'Gekoppeld aan',
  defectCategories: {
    structural: 'Bouwkundig',
    setup: 'Installatie',
    organisation: 'Organisatie',
    culture: 'Cultuur',
    conformity: 'Gelijkwaardigheid'
  },
  damageLimitTarget: {
    title: 'Doel beperken schade',
    low: 'Schade van derde',
    high: 'Eigen schade'
  },
  deadline: 'Deadline',
  description: 'Omschrijving',
  defect: 'Tekortkoming | Tekortkomingen',
  defectCount: 'Geen Tekortkomingen | 1 Tekortkoming | {count} Tekortkomingen',
  defectAndTask: 'Tekortkoming en taak',
  defects: 'Tekortkoming',
  defectsSolved: 'tekortkomingen verholpen.',
  delete: 'Verwijderen',
  deleted: 'Verwijderd',
  deleteUser: 'Gebruiker verwijderen',
  deleteScan: 'Scan verwijderen',
  downloadReport: 'Rapport downloaden',
  department: 'Afdeling',
  doing: {
    editing: 'Aan het bewerken'
  },
  domain: 'Domein',
  doNotAcceptDeviations: 'Accepteer geen afwijkingen',
  doNotAcceptDeviationsBuildingDecree: 'Accepteer geen afwijkingen t.o.v. eisen van het Bouwbesluit',
  dontWarnDontActIndependently: 'Waarschuwt niet en handelt niet zelfstandig (C)',
  dragDrop: 'Drag & Drop een bestand',
  drawings: 'Tekeningen',
  editing: 'Bewerken',
  editDrawing: 'Tekening bewerken',
  editName: 'Naam wijzigen',
  editUser: 'Bewerk gebruiker',
  email: 'E-mail',
  emailAddress: 'E-mailadres',
  emailInUse: 'E-mailadres is al in gebruik',
  entities: 'Entiteiten',
  entity: 'Entiteit',
  entityItem: {
    delete: 'Weet je zeker dat je de selectie wilt verwijderen?'
  },
  expiresAt: 'Verloopt op',
  expiresIn: 'Dagen tot verloop',
  entityMissingSettings: 'Er ontbreken instellingen voor de geselecteerde entiteit of de afhankelijkheden ervan. Configureer eerst uw entiteiten voordat u een scan maakt',
  entitySettings: {
    buildingType: 'Type Gebouw',
    selectBuildingType: 'Selecteer een type gebouw',
    selectTypeBeforeSave: 'Om instellingen bij te werken dient u eerst het type van uw nieuwe entiteiten toe te wijzen'
  },
  entityTypes: {
    location: 'locatie',
    building: 'gebouw',
    buildingPart: 'gebouwdeel',
    department: 'afdeling',
    residence: 'Woning',
    fallback: 'entiteit'
  },
  expired: 'Verlopen',
  expert: 'Expert',
  expertBuilding: 'Expert',
  expertBuildingPart: 'Expert',
  expiredScans: 'Verlopen scans',
  allEntities: 'Alle entiteiten',
  expiresOn: 'Deadline',
  failed: 'Mislukt',
  fieldsNotFilled: 'Sommige velden zijn niet ingevuld',
  file: 'Bestand',
  fileName: 'Bestandsnaam',
  pdfUploader: 'Voeg een bestand toe...',
  fileUploadSuccess: 'Het bestand is succesvol geüpload',
  filterBox: 'Geef uw filter op',
  firstName: 'Voornaam',
  function: 'Functie',
  givenAnswer: 'Gegeven antwoord',
  goal: 'Doel',
  healthcare: 'Zorgverlener',
  high: 'Hoog',
  hideArchivedScans: 'Verberg gearchiveerde scans',
  history: {
    activity: 'Activiteit',
    description: 'Omschrijving',
    noSelection: 'Selecteer een activiteit om de gegevens in te zien.',
    'defect-create': 'Scan is voltooid, tekortkoming is ontstaan.',
    create: '{user} heeft een taak aangemaakt.',
    update: '{user} heeft de taak aangepast.',
    delete: '{user} heeft de taak verwijderd.',
    status: {
      '6ec42d58-a334-4552-be08-fb17e32189d4': 'open', // Pending
      '6f989de7-68b6-4421-8ceb-17909b9eac11': 'ter goedkeuring', // Awaiting approval
      '63df9ad6-86cd-44f3-91c8-da4166e84d63': 'goedgekeurd' // Approved
    },
    statusMessage: {
      '6ec42d58-a334-4552-be08-fb17e32189d4': '{user} heeft de taak {status} gezet.', // Pending
      '6f989de7-68b6-4421-8ceb-17909b9eac11': '{user} heeft de taak {status} gemeld.', // Awaiting approval
      '63df9ad6-86cd-44f3-91c8-da4166e84d63': '{user} heeft de taak {status}.' // Approved
    },
    detail: {
      Title: 'Titel',
      Description: 'Omschrijving',
      TaskStatusId: 'Status',
      UserId: 'Toegewezen aan',
      Deadline: 'Deadline',
      CreatedBy: 'Aangemaakt door',
      CreatedAt: 'Aangemaakt op',
      ModifiedBy: 'Aangepast door',
      ModifiedAt: 'Aangepast op',
      DeletedBy: 'Verwijderd door',
      DeletedAt: 'Verwijderd op',
      Comments: 'Opmerkingen',
      create: 'Taak aangemaakt',
      update: 'Taak aangepast',
      delete: 'Taak verwijderd',
      'defect-create': 'Tekort ontstaan'
    }
  },
  invalidLink: 'Sorry de link is niet meer geldig',
  lastName: 'Achternaam',
  lastScan: 'Laatste scan',
  load: {
    answers: 'Antwoorden inladen'
  },
  location: 'Locatie',
  locations: 'Locaties',
  loginFailed: 'Inloggen mislukt. Controleer uw e-mailadres en wachtwoord',
  logout: 'Uitloggen',
  low: 'Laag',
  linkedTo: 'Gelinked aan',
  manageCompanies: 'Beheer bedrijven',
  taskManagement: 'Beheer',
  taskManagementPage: {
    title: 'Beheer je tekortkomingen en behaal je ambitieniveau',
    description: 'Tekortkomingen zijn opgedeeld in categorieën. Verhelp een tekortkoming door ze als taak toe te wijzen aan personen. Zodra de taak is uitgevoerd, zal de staat van de Brandcheck analyse direct worden bijgewerkt.  Let er op dat het niet mogelijk is om een nieuwe scan te starten wanneer nog er nog tekortkomingen openstaan.',
    adminDescription: 'Tekortkomingen zijn opgedeeld in categorieën. Verhelp een tekortkoming door ze als taak toe te wijzen aan personen. Zodra de taak is uitgevoerd, zal de staat van de Brandcheck analyse direct worden bijgewerkt.  Let er op dat het niet mogelijk is om een nieuwe scan te starten wanneer nog er nog tekortkomingen openstaan.',
    standardDescription: 'Uw toegewezen taken zijn opgedeeld in categorieën. Verhelp een tekortkoming door de taak uit te voeren.',
    completed: 'Afgerond',
    question: 'Vraag',
    answer: 'Antwoord',
    class: 'Klasse',
    task: 'Taak',
    openTask: 'Geen taken | 1 Taak | { count } Taken',
    assignedTo: 'Toegewezen aan',
    deadline: 'Deadline',
    pin: 'Pin',
    emptyStateHeader: 'Geen tekortkomingen',
    emptyStateBodyStart: 'Voor de categorie ',
    emptyStateBodyEnd: ' zijn er geen tekortkomingen.',
    status: 'Status'
  },
  media: {
    loaded: 'Media geladen',
    loading: 'Media aan het laden',
    media: 'Media',
    missing: 'Media niet gevonden',
    open: 'Open Media',
    pinMissing: 'Pin niet gevonden'
  },
  middle: 'Midden',
  middleName: 'Tussenvoegsel',
  multiselect: {
    search: 'Zoeken',
    clearSelection: 'Toevoegen'
  },
  name: 'Naam',
  nameTaken: 'Deze naam is al in gebruik',
  newDrawings: 'Tekening toevoegen',
  newScan: 'Scan toevoegen',
  newUser: 'Nieuwe gebruiker',
  noPhotos: 'Geen foto\'s aanwezig',
  noDataAvailable: 'Lekker bezig! Alles is up-to-date.',
  notApplicable: 'Niet van toepassing',
  note: 'Notitie',
  notRecentlyScanned: 'Niet (recent) gescand',
  noUnsafeBehavior: '(Waarschijnlijk) geen onveilig gedrag',
  numbersOfDefect: 'Aantal defecten',
  operationalGoals: 'Operationele doelen',
  or: 'Of',
  organization: 'Organisatie',
  originalFileName: 'Origineel bestand',
  passed: 'Behaald',
  password: 'Wachtwoord',
  passwordChangeRequest: 'Wachtwoord wijzigen aanvragen',
  passwordsDontMatch: 'Wachtwoorden komen niet overeen',
  pieChart: {
    BuildingIntramural: 'Gebouw | Gebouwen',
    BuildingExtramural: 'Gebouw | Gebouwen',
    BuildingUtility: 'Gebouw | Gebouwen',
    BuildingPart: 'Gebouwdeel | Gebouwdelen',
    BuildingPartUtility: 'Gebouwdeel | Gebouwdelen',
    Department: 'Afdeling | Afdelingen'
  },
  pending: 'In afwachting',
  postCode: 'Postcode',
  progress: 'Voortgang',
  question: 'Vraag',
  questions: 'Vragen',
  questionsAndAnswers: 'Vragen en antwoorden',
  questionAndAnswer: 'Vraag en antwoord',
  reference: 'Referentie',
  remove: 'Verwijderen',
  removeLastScan: 'Verwijder laatste scan',
  report: {
    generate: 'Genereer',
    busy: 'Bezig...',
    downloadcsv: 'Download rapport (.csv)',
    download: 'Download rapport',
    welcomeMessage: 'Wilt u een rapportage genereren voor deze scan?',
    downloadMessage: 'Uw rapportage wordt gegenereerd.',
    successMessage: 'Uw rapportage staat klaar! Klik op \'Download\' om het downloaden te starten.',
    errorMessage: 'Er is een fout opgetreden tijdens het genereren van uw rapport. Probeer het later opnieuw.',
    title: 'Genereer rapportage voor {entityName}'
  },
  requestError: 'Er is een fout opgetreden bij het verwerken van uw verzoek',
  requestPasswordSuccess: 'Reset wachtwoord aangevraagd met succes!',
  residence: 'Woning',
  residentialBuilding: 'Woongebouw',
  results: 'Resultaten',
  restoreArchive: 'Uit archief halen',
  restoreArchiveSuccess: 'Scan is uit het archief gehaald',
  restoreArchiveError: 'Het is niet gelukt om de scan uit het archief te halen',
  riskApproach: 'Risicobenadering',
  riskAssessment: {
    title: 'Risicobenadering',
    tooltip_1: 'Met een risicogestuurde aanpak kom je tot afgewogen keuzes. Daarbij bepaal je bewust wat je wel en niet doet en welke restrisico’s\n' +
      '        acceptabel zijn. Je kijkt met deze aanpak naar alle te verwachten risico’s en risicofactoren die samenhangen met het ontstaan en de\n' +
      '        effecten van brand en vertaalt die naar eigen beleid en maatregelen op bouwkundig, installatietechnisch en organisatorisch vlak.\n' +
      '        Risico’s vind je niet alleen in het gebouw en de installaties, maar vooral ook in het gebruik ervan, waarbij cliënt-/patiëntkenmerken\n' +
      '        en -gedrag, medewerkers, andere aanwezigen en de omgeving belangrijke factoren zijn.',
    tooltip_2: 'Het uitgangspunt is dat risico’s zijn in te schatten, af te wegen en te beperken. Door bewust met de risico-inventarisatie om te gaan,\n' +
      '        is dit in een proces te sturen en kunnen de ingezette maatregelen worden geëvalueerd op de bruikbare toepassing ervan. Hoe groter de\n' +
      '        samenhang tussen de maatregelen, hoe kleiner het risico op brandgevaarlijke situaties en de gevolgen daarvan.'
  },
  save: 'Opslaan',
  saveChanges: 'Veranderingen opslaan',
  settings: 'Instellingen',
  scan: {
    add: 'Scan toevoegen',
    showArchived: 'Gearchiveerde scans',
    archived: {
      hide: 'Verberg gearchiveerde scans',
      show: 'Toon gearchiveerde scans'
    },
    scan: 'Scan',
    scanCreated: 'Er is een nieuwe scan aangemaakt'
  },
  scanDetail: 'Scan Detail',
  scanEntityType: 'Gelinked aan',
  scans: 'Scans',
  scanStatus: {
    notDone: 'scan niet gedaan | scans niet gedaan',
    failed: 'scan gefaald | scans gefaald',
    succesfull: 'scan geslaagd | scans geslaagd',
    expired: 'scan verlopen | scans verlopen'
  },
  scansProgress: 'Voortgang scans',
  scanType: 'Type scan',
  select: 'Selecteren',
  selectABuildingPartOrDepartment: 'Afhankelijk van een geselecteerd gebouw, gebouwdeel of afdeling.',
  selectDate: 'Selecteer een datum',
  selectFirstName: 'Vul een voornaam in',
  selectLastName: 'Vul een achternaam in',
  selectMiddleName: 'Vul een tussenvoegsel in',
  selectEmail: 'Vul een e-mailadres in',
  showArchivedScans: 'Toon gearchiveerde scans',
  status: 'Status',
  statuses: {
    'Awaiting approval': 'Goedkeuring vereist',
    Pending: 'Open',
    Approved: 'Goedgekeurd'
  },
  subcategory: 'Subcategorie',
  submit: 'Aanvragen',
  submitSuccessfull: 'Aanvraag geslaagd',
  archiveScanTitle: 'Scan archiveren',
  sureArchiveScan: 'Weet u het zeker dat u deze scan wilt archiveren?',
  scanArchivedSuccess: 'Uw scan is gearchiveerd',
  archiveMediaTitle: 'Foto verwijderen',
  sureArchiveMedia: 'Weet u zeker dat u deze foto wilt verwijderen?',
  mediaArchivedSuccess: 'Uw foto is verwijderd',
  mediaCreatedSuccess: 'Uw foto is opgeslagen',
  sureDelete: 'Weet u het zeker dat u deze gebruiker wilt verwijderen?',
  stateScan: {
    1: 'In afwachting',
    2: 'Volbracht',
    3: 'Verlopen',
    4: 'Gearchiveerd',
    5: 'Verwijderd'
  },

  alertModal: {
    title: 'Let op!'
  },

  task: {
    task: 'Taak',
    add: 'Taak toevoegen',
    edit: 'Taak bewerken',
    history: 'Geschiedenis',
    createAndAssign: 'Taak opstellen en toewijzen',
    notifyCompleted: 'Taak uitgevoerd en melden',
    deleteTask: 'Weet je zeker dat je deze taak wil verwijderen?'
  },
  title: 'Titel',
  topMenu: {
    addCard: 'Tegels',
    cards: 'Kaarten',
    expiring: 'Verlopen',
    lock: 'Slot',
    myScans: 'Mijn Scans',
    unlock: 'Ontsluiten',
    userScans: 'Gebruikersscans',
    usageFunctions: 'Gebruik Functies',
    compliance: 'Conform 7.11a',
    companyDeadlines: 'Deadlines',
    ambitionAllBuildings: 'Ambitieniveaus gebouwen'
  },
  total: 'totaal',
  sureDeleteEntity: 'Weet u het zeker dat u deze locatie en eventuele afhankelijke locaties wilt verwijderen?',
  sureDeleteScan: 'Weet u het zeker dat u deze scan wilt verwijderen?',
  type: 'Type',
  turnOffNewScan: 'Zet een nieuwe scan uit',
  upload: 'Uploaden',
  uploadedAt: 'Geüpload op',
  uploadedBy: 'Geüpload door',
  usageFunction: {
    '00000000-0000-0000-0000-000000000000': '-',
    'ca36c55a-d67c-434c-a6b6-efbbdccc0055': 'Bestaande bouw - Woonfunctie senioren - geen woongebouw',
    'b20e0079-16d3-43c4-8508-e9a4504beec9': 'Bestaande bouw - Woonfunctie senioren - woongebouw',
    '7ec2980c-8660-47c1-b362-742514bc94da': 'Bestaande bouw - Gezondheidszorgfunctie zonder bedgebied',
    '63866e37-1385-44ef-817e-1fa80835b4a7': 'Bestaande bouw - Gezondheidszorgfunctie met bedgebied',
    '4353da6a-4314-4ab7-b71c-8777682464b6': 'Bestaande bouw - Woonfunctie met zorg in woongebouw',
    '91f304f7-c1d6-4b6c-9065-358c06e150fc': 'Bestaande bouw - Woonfunctie met zorg grond < 500 m²',
    'ed23ab68-787b-4d90-89ba-47b479d3e241': 'Bestaande bouw - Bijeenkomstfunctie algemeen',
    '53dc9916-d287-473b-8e93-21620f8e1855': 'Bestaande bouw - Bijeenkomstfunctie in de zorg',
    '602081bb-f28f-4a2d-a5ba-177dae3c8d79': 'Bestaande bouw - Celfunctie - algemeen',
    '021c3ea2-a533-46a7-9f72-728c2cca740f': 'Bestaande bouw - Kantoorfunctie',
    '4fe0c545-ee33-45bc-8e83-5596bc6092c7': 'Nieuwbouw - Woonfunctie senioren - geen woongebouw',
    'c7d9016d-377d-4052-b757-a291edb6e2af': 'Nieuwbouw - Wonen met zorg, groepszorgwoning gelegen in een woongebouw',
    'f7d6bed0-9a5f-4132-b18d-2bb0098b342d': 'Nieuwbouw - Gezondheidszorgfunctie zonder bedgebied',
    '65af8ac5-7f2f-40a0-b026-c04f89c9deb3': 'Nieuwbouw - Gezondheidszorgfunctie met bedgebied',
    'fb94f9cc-90b9-4bd1-b61c-75ba9d03f432': 'Nieuwbouw - Woonfunctie met zorg in woongebouw',
    '11ec553b-6fdb-4963-8830-ce5594c0a5b3': 'Nieuwbouw - Woonfunctie met zorg grond < 500 m²',
    '67515a9f-f0c9-44a8-9f94-255b4001f5ff': 'Nieuwbouw - Bijeenkomstfunctie algemeen',
    'deded1e2-0d46-447b-88dd-df304d3eba98': 'Nieuwbouw - Bijeenkomstfunctie in de zorg',
    '9525f1ac-c0ec-4bde-82ff-17dd275d9d62': 'Nieuwbouw - Celfunctie - algemeen',
    'b83f6f85-deb8-48e3-9c19-3dcdd9d06f5d': 'Nieuwbouw - Kantoorfunctie',
    '270954f3-98f7-4484-af58-4bd3acac84a9': 'Nieuwbouw - Woonfunctie algemeen - geen woonwagens',
    '253f565f-54b5-4750-8382-9b24eacb8239': 'Nieuwbouw - Woonfunctie voor kamergewijze verhuur',
    '5147292e-4b53-4c62-aa77-82b6d27059f8': 'Bestaande bouw - Woonfunctie algemeen - geen woonwagens',
    '3bda4760-1957-4571-a2b1-5a7ace004b81': 'Bestaande bouw - Woonfunctie voor kamergewijze verhuur',
    '6d6a4710-4170-47bd-bd9c-4e9d48584062': 'Nieuwbouw - Onderwijsfunctie',
    'a96bae92-bb63-4645-8e26-ced0cbeb7b69': 'Nieuwbouw - Sportfunctie',
    '2abd4f47-df22-46ff-9e82-dc99f9855f6e': 'Nieuwbouw - Winkelfunctie',
    '46611c2d-3776-4330-9a42-b6c115c68eca': 'Bestaande bouw - Onderwijsfunctie',
    '548ae6de-12f7-4396-be0d-0775b9727f22': 'Bestaande bouw - Sportfunctie',
    'a0fae56d-865d-4240-baec-1c2c7e54a47d': 'Bestaande bouw - Winkelfunctie',
    notApplicable: 'Niet van toepassing'
  },
  photos: 'Foto(’s)',
  users: 'Gebruikers',
  userRole: 'Gebruikersrol',
  userType: 'Type gebruiker',
  userDeleted: 'De geselecteerde gebruiker is verwijderd.',
  userNotDeleted: 'De geselecteerde gebruiker is niet verwijderd.',
  validating: 'validerende',
  victimsLimitTarget: {
    title: 'Doel beperken van slachtoffers',
    low: 'Bouwbesluit',
    high: 'Beter dan het Bouwbesluit',
    description_1: 'Op basis van het belang van een maatregel/eis uit het Bouwbesluit, het gekozen ambitieniveau, het risicoprofiel van de cliëntgroep, de zekerheid van een tekortkoming en de mate van het tekort (dit betekent: aan de hand van het gegeven antwoord), is een klassering gegeven aan de afwijking/tekortkoming met de volgende categorieën:',
    description_2: 'Hier kan de keuze gemaakt worden om voor maatregelen met een tekort categorie E of de categorieën D en E,\n' +
      '        te besluiten om deze niet mee te nemen in de overzichten. Daarbij moet men zich wel realiseren dat daarmee een afwijking t.o.v.\n' +
      '        de eisen Bouwbesluit wordt geaccepteerd.',
    importance_1: 'Weinig belangrijk',
    importance_2: 'Minder belangrijk',
    importance_3: 'Tamelijk belangrijk',
    importance_4: 'Belangrijk',
    importance_5: 'Zeer belangrijk'
  },
  viewLastScan: 'Bekijk laatste scan',
  warnsAndActs: 'Waarschuwt en handelt (A)',
  warnsAndDontActIndependently: 'Waarschuwt en handelt niet zelfstandig (B)',
  workFloor: 'Medewerker',
  finished: 'Volbracht',

  configuration: {
    title: 'Titel',
    unnamed: 'Naamloos',
    lastChanged: 'Laatst gewijzigd'
  },

  evacuationPlan: {
    evacuationStrategies: 'Ontruimingsstrategie',
    evacuationType: 'Type ontruiming',
    evacuationStrategyId: 'Ontruimingsstrategie',
    evacuationTypeId: 'Type ontruiming',
    maskReady: 'Vluchtmasker',
    maxCasualties: 'Maximaal aantal slachtoffers',
    minMargin: 'Minimale marge beschikbaar/nodig',
    header: 'Ontruimingsplan en doelstelling',
    'Vuurhaard eerst': 'Vuurhaard eerst',
    'Vuurhaard overslaan': 'Vuurhaard overslaan',
    'Vuurhaard Stay-in place': 'Stay-in-place',
    Stil: 'Stil',
    Luid: 'Luid',
    true: 'Ja',
    false: 'Nee'
  },
  escapeRoutes: {
    tabs: {
      source: 'Vuurhaard',
      adjacentArea: 'Naastliggende ruimte'
    },
    inputLabels: {
      persons: 'Aantal personen',
      warrRating: 'WARR - Rating',
      requiredTime: 'Benodigde tijd',
      description: 'Beschrijving'
    }
  },
  proximity: {
    1: 'De ruimte zelf',
    2: 'Aangrenzende ruimte'
  },

  fires: {
    smoldering: 'Smeulende brand',
    small: 'Kleine brand',
    middle: 'Middelgrote brand',
    large: 'Grote brand',

    '2a713979-1766-4a2e-a4c1-d9976a5d66b1': 'Smeulende brand',
    'b6939668-4c62-4abc-8004-dae069726de3': 'Kleine brand',
    'd94458b2-a67d-45f4-a475-f89e36f107a9': 'Middelgrote brand',
    'd88aa747-299d-4e9b-81dd-b53345ab6861': 'Grote brand'
  },

  scenario: {
    import: 'Scenario importeren',
    title: 'Scenario verwijderen',
    text: 'Weet u zeker dat u dit scenario wilt verwijderen?'
  },

  navigation: {
    rooms: 'De ruimtes',
    fireScenarios: 'Scenario\'s',
    teams: 'BHV en brandweerploegen',
    measureConfig: 'Bouw en installaties',
    evacuationPlan: 'Ontruimingsplan en doelstelling',
    overview: 'Overzicht'
  },
  rooms: {
    header: 'Wat zijn de ruimtes van uw gebouw?',
    description: 'Dit kunnen losse ruimtes zijn of een combinatie van ruimtes. Twee aaneengrenzende kamers kunt u dus als een gecombineerde ruimte definiëren door de oppervlaktes bij elkaar op te tellen.',
    area: 'Oppervlakte',
    persons: 'Aantal personen',
    modal: {
      addRoom: 'Ruimte toevoegen',
      editRoom: 'Ruimte bewerken',
      title: 'Naam ruimte',
      persons: 'Aantal personen',
      warr: 'WARR',
      requiredTime: 'Benodigde tijd',
      placeHolderRoomName: 'Selecteer WARR'
    },
    defaultRoom: 'Ruimte 1',
    warrLabel: {
      W0: 'Alleen waarschuwen',
      W1: 'Waarschuwen, 1 BHV\'er',
      W2: 'Waarschuwen, 2 BHV\'ers',
      W3: 'Waarschuwen, 3 BHV\'ers',
      A1: 'Assisteren, 1 BHV\'er',
      A2: 'Assisteren, 2 BHV\'ers',
      A3: 'Assisteren, 3 BHV\'ers',
      A4: 'Assisteren, 4 BHV\'ers',
      R1: 'Begeleiden, 1 BHV\'er',
      R2: 'Begeleiden, 2 BHV\'ers',
      R3: 'Begeleiden, 3 BHV\'ers',
      R4: 'Begeleiden, 4 BHV\'ers',
      fireSource: 'Vuurhaard definiëren',
      overview: 'Scenario overzicht'
    }
  },
  teams: {
    bHV: {
      tab: 'BHV Ploegen',
      header: 'BHV ploeg',
      legend: 'Aantal BHV\'ers'
    },
    BW: {
      tab: 'Brandweerploegen',
      header: 'Brandweerploeg',
      legend: 'Aantal brandweerlieden'
    },
    arrivalTime: 'Opkomsttijd (s)',
    available: 'Aantal BHV\'ers',
    bhvCountAtGatherPoint: 'BHV\'ers op de verzamelplaats na 1e ontruiming',
    teams: 'Ploegen',
    emergencyResponders: 'BHV\'ers',
    timeInSeconds: 'seconden opkomsttijd'
  },
  installation: {
    header: 'Wat is de staat van het gebouw?',
    'Muren Brandhaard': {
      title: 'Brandwerende muren',
      'Niet brandwerend (2 min)': 'Niet brandwerend (2 min)',
      'Brandwerend (15 min)': 'Brandwerend (15 min)',
      'Brandwerend (30 min)': 'Brandwerend (30 min)'
    },
    'Rookwerendheid doorgangen': {
      title: 'Rookwerendheid doorgangen',
      'Deuren open': 'Deuren open',
      'Deuren dicht met rooklekkage': 'Deuren dicht met rooklekkage',
      'Deuren volledig gesloten': 'Deuren volledig gesloten'
    },
    'Type Detectie': {
      title: 'Type detectie',
      'Optische detectie': 'Optische detectie',
      'Multicriteria detectie (incl. Gas)': 'Multicriteria detectie (inclusief gas)',
      'Aspiratie detectie': 'Aspiratie detectie'
    },
    Detectiegraad: {
      title: 'Dekkingsgraad detectie',
      'Volledige detectie': 'Volledige detectie',
      'Gedeeltelijke detectie': 'Gedeeltelijke detectie',
      'Niet-aut. Detectie': 'Niet-automatische detectie'
    },
    Handblusmiddel: {
      title: 'Handblusmiddel',
      Geen: 'Geen',
      'Brandblusser 8A/27A (>60m²)': 'Brandblusser 8A/27A (> 60m²)',
      'Blusser 8A/27A + Aerosolblusmiddel': 'Blusser 8A/27A + Aerosolblusmiddel'
    },
    Blussysteem: {
      title: 'Sprinklersysteem',
      Geen: 'Geen',
      Woningsprinkler: 'Woningsprinkler',
      'Sprinkler / watermist': 'Sprinkler',
      'Rookgestuurde Watermist': 'Watermist'
    },

    detectionTime: 'Detectie tijd (s)'
  },

  scenarios: {
    header: 'Wie moet waar vandaan gered worden?',
    description: 'Het kan zijn dat er meerdere risico volle plekken zijn voor het ontstaan van een brand. Hier kunnen meerdere scenario’s gecreëerd worden met de gedefinieerde ruimtes, zodat straks alle risico’s getest kunnen worden.',
    defaultScenario: 'Scenario 1',
    amountOfScenarios: 'Aantal opstellingen',
    modal: {
      add: 'Scenario toevoegen',
      edit: 'Scenario aanpassen',
      title: 'Scenario naam',
      placeHolderTitle: 'Titel...',
      placeHolderRoomName: 'Selecteer een ruimte'
    },
    rooms: {
      from: 'Vanuit',
      through: 'Door',
      upperCase: {
        A: 'Vuurhaard',
        B: 'Naastliggende ruimte',
        C: 'Naastliggende ruimte +1',
        D: 'Naastliggende ruimte +2'
      },
      lowerCase: {
        A: 'vuurhaard',
        B: 'naastliggende ruimte',
        C: 'naastliggende ruimte +1',
        D: 'naastliggende ruimte +2'
      },
      behind: 'Achterliggende ruimte'
    },
    inputLabels: {
      persons: 'Aantal personen',
      assistance: 'Begeleiding',
      requiredTime: 'Benodigde tijd',
      inSeconds: '(s)',
      description: 'Omschrijving',
      fillDescription: 'Omschrijving invullen...'
    },
    detail: {
      arButton: 'ASET / RSET',
      advancedData: 'Geavanceerde gegevens',
      aset: 'ASET',
      rset: 'RSET',
      clearing: 'Ontruimen ',
      generalData: 'Algemene gegevens',
      evacuation: 'Ontruiming door BHV',
      accessibility: 'Toegankelijkheid tijdens ontruiming',
      editEntityLink: 'Wijzig koppeling',
      editSettings: 'Wijzig instellingen',
      viewResult: 'Bekijk resultaat'
    }
  },

  vuurhaard: {
    header: 'Waar vindt de brand plaats?',
    localRoom: 'Ruimte zelf',
    adjacentRoom: 'Achterliggende ruimte',
    area: 'Oppervlakte',
    A: 'Vuurhaard',
    B: 'Naastliggende ruimte',
    C: 'Naastliggende ruimte + 1',
    D: 'Naastliggende ruimte + 2'
  },

  overview: {
    scenarioOverview: 'Scenario overzicht',
    header: 'Overzicht',
    testScenario: 'Check ontruiming',
    adjust: 'Aanpassen',
    configurationTitleAndConnection: 'Ontruimcheck titel & koppeling',
    saveConfiguration: 'Ontruimcheck opslaan',
    layout: 'De ruimtes',
    evacuationDetails: 'Vluchtgegevens',
    installation: 'Bouw en installaties',
    teams: 'BHV en brandweerploegen',
    evacuationPlan: 'Ontruimingsplan en doelstelling',
    persons: 'Personen',
    requiredTime: 'seconden benodigde tijd',
    modal: {
      title: 'Titel',
      placeHolderTitle: 'Titel...',
      attachToBuilding: 'Koppelen aan de gebouwvoorraad',
      detach: 'Ontkoppel'
    }
  },

  result: {
    adjustSettings: 'Wijzig instellingen',
    passed: 'behaald',
    failed: 'niet behaald',
    max: 'Maximum',
    maxCasualties: 'slachtoffer | slachtoffers',
    seeResult: 'Bekijk resultaat',
    seeScenario: 'Bekijk het scenario',
    title: 'Resultaat',
    casualties: 'Slachtoffers',
    injured: 'Gewonden',
    unharmed: 'Ongedeerd',
    saved: 'Gered',
    save: 'Opslaan',
    evacuateRoom: '{room} ontruimen',
    detail: {
      roomData: '{room} gegevens',
      roomEvacuate: '{room} ontruimen',
      roomAccessibility: 'Toegankelijkheid {room} tijdens de ontruiming',
      startLethality: 'Intreden letaliteit',
      evacuationBy: 'Ontruiming door',
      startEvacuation: 'Begin ontruiming',
      runsSucceeded: 'Runs geslaagd',
      runsRequired: 'Runs nodig'
    },
    unsuccessful: 'mislukt',
    successful: 'geslaagd',
    seconds: 'seconde | seconden'
  },

  general: {
    logout: 'Uitloggen',
    ok: 'OK',
    cancel: 'Annuleren',
    edit: 'Bewerken',
    delete: 'Verwijderen',
    download: 'Downloaden',
    search: 'Zoeken',
    back: 'Terug',
    add: 'Toevoegen',
    accept: 'Accepteren',
    save: 'Opslaan',
    dismiss: 'Afwijzen',
    general: 'Algemeen',
    settings: 'Instellingen',
    continue: 'Doorgaan',
    close: 'Sluiten',
    confirm: 'Bevestigen',
    previous: 'Vorige',
    next: 'Volgende',
    done: 'Klaar',
    yes: 'Ja',
    no: 'Nee',
    date: 'Datum',
    max: 'Max.',
    min: 'Min.',
    unexpectedError: 'Een onverwachte fout is opgetreden.',
    warning: 'Let op!',
    select: 'Selecteer'
  },

  signUp: {
    signUp: 'Registreren',
    fillForm: 'Vul hier uw gegevens in om een account aan te maken',
    duplicateUser: 'Dit e-mailadres is al in gebruik of er is een verkeerd e-mailadres opgegeven.',
    passwordRequirements: 'Ten minste één hoofdletter, één speciaal teken en een cijfer bevatten.',
    passwordMismatch: 'De wachtwoorden komen niet overeen',
    firstName: 'Voornaam:',
    lastName: 'Achternaam:',
    email: 'E-mail:',
    password: 'Wachtwoord:',
    confirmPassword: 'Wachtwoord bevestigen:',
    createFail: 'Kon uw account niet aanmaken. Neem contact op met onze support-afdeling.'
  },
  login: {
    featureTitle: 'Tools 4 building quality',
    featureDescription: 'een initiatief van TNO en ISSO',
    logIn: 'Inloggen',
    email: 'E-mail',
    password: 'Wachtwoord',
    signUp: 'Registreren',
    userNotFound: 'De gebruiker bestaat niet.',
    wrongPassword: 'Wachtwoord klopt niet.',
    error: 'Er is iets mis gegaan',
    incorrect: 'Uw e-mail of wachtwoord is incorrect.',
    incorrectEmail: 'Vul uw e-mail in',
    forgotPassword: 'Wachtwoord vergeten?'
  },
  forgotPassword: {
    featureTitle: 'Wachtwoord vergeten?',
    featureDescription: 'Voer uw e-mailadres in om een nieuw wachtwoord aan te vragen.'
  },
  resetPassword: {
    featureTitle: 'Herstel uw wachtwoord',
    featureDescription: 'Voer een nieuw wachtwoord in',
    reset: 'Herstel',
    tryAgain: 'Probeer opnieuw',
    passwordNew: 'Nieuw wachtwoord',
    passwordConfirm: 'Bevestig wachtwoord',
    passwordMismatch: 'Wachtwoorden komen niet overeen',
    passwordRequired: 'Voer een nieuw wachtwoord in',
    passwordRequirements: 'Uw wachtwoord moet ten minste één hoofdletter, één speciaal teken en een cijfer bevatten.',
    successClick: 'Klik om in te loggen.',
    failed: 'Herstellen mislukt',
    success: 'Wachtwoord succesvol aangepast.',
    continueToLogIn: 'Ga door naar inlogpagina.'
  },
  emailSent: {
    title: 'Controleer uw inbox!',
    forgotPasswordMessage: 'Als dit e-mailadres bij ons bekend is, ontvangt u een bericht met verdere instructies.',
    signUpMessage: 'Als dit e-mailadres nog niet bij ons bekend is, ontvangt u een bericht met verdere instructies.',
    resendLink: 'Opnieuw verzenden',
    processing: 'Verwerken...',
    done: 'Uw account is bevestigd!',
    showError: 'Er ging iets mis. Probeer het opnieuw.',
    confirmSign: 'Afronden en inloggen',
    confirmMessage: 'Klik om uw account te bevestigen.',
    redelivered: 'Het bericht is opnieuw verzonden'
  },

  menu: {
    dashboard: 'Dashboard',
    entities: 'Gebouwen',
    users: 'Gebruikers',
    drawings: 'Tekeningen',
    brandcheck: 'Brandcheck',
    analyseBuilding: 'Analyses',
    overviewscan: 'Scans',
    ontruimcheck: 'Ontruimcheck',
    wizard: 'Opstellen',
    list: 'Overzicht',
    taskManagement: 'Beheer'
  },

  drawing: {
    unknownLocation: '(Locatie verwijderd)',
    title: 'Tekening verwijderen',
    deleteDrawing: 'Weet u zeker dat u deze tekening wilt verwijderen?',
    add: 'Tekening toevoegen',
    empty: {
      heading: 'Waar was het ook alweer?',
      text: 'Voeg tekeningen toe, zodat u pins kunt plaatsen om duidelijkheid te creëren.'
    }
  },

  ontruimcheck: {
    amountOfScenarios: 'Aantal opstellingen',
    user: 'Gebruiker',
    date: 'Datum',
    target: 'Doel',
    leave: {
      title: 'Ontruimcheck verlaten',
      body: 'Weet u zeker dat u deze Ontruimcheck wilt verlaten? De wijzigingen worden niet opgeslagen.'
    }
  },

  compliance: {
    department: 'Afdeling/Woning',
    goal: 'Doelstelling',
    success: 'Conform 7.11a',
    failed: 'Niet conform 7.11a',
    casualties: 'slachtoffer | slachtoffers',
    completed: 'gebouwen voltooid'
  },

  toast: {
    info: 'Info',
    success: 'Succes',
    warning: 'Waarschuwing',
    error: 'Fout',
    editSuccess: 'Bewerking opgeslagen',
    hasBeenDeleted: 'is verwijderd',
    generateStart: 'Uw scan-rapportage wordt gegenereerd!',
    downloadFailed: 'Er is iets misgegaan.'
  },

  dashboard: {
    goodMorning: 'Goedemorgen',
    goodAfternoon: 'Goedemiddag',
    goodEvening: 'Goedenavond',
    insufficientScans: 'Zorg dat al uw gebouwen, delen, afdelingen en woningen hun ambitieniveau’s behalen.',
    building: 'gebouw | gebouwen',
    buildingPart: 'gebouwdeel | gebouwdelen',
    residence: 'woning | woningen',
    analysis: 'analyse | analyses',
    defect: 'tekortkoming | tekortkomingen',
    withACombined: 'met in totaal',
    nothingToResolve: 'Beheer uw tekortkomingen en maak taken aan om deze op te lossen.',
    thereIs: 'Er is | Er zijn',
    solveResultPreface1: 'Door dit tekort | Door deze tekorten',
    solveResultPreface2: 'op te lossen zal er | op te lossen zullen er',
    willMeetAmbition: 'het ambitieniveau behalen.',
    solveResultAdditional: 'en',
    solveResultEnd1: ', waar een totaal van',
    solveResultEnd2: 'taken voor open staan.',
    toAnalysis: 'Bekijk analyses',
    toTaskManagement: 'Beheer tekortkomingen',
    allLocations: 'Alle locaties'
  },

  user: {
    add: 'Gebruiker toevoegen',
    added: 'Gebruiker toegevoegd',
    modified: 'Gebruiker gewijzigd',
    roleName: {
      '16a84185-7d5e-4c44-9b5e-05334450f750': 'Werknemer',
      '3fe8be61-3d4c-46dd-bd5a-c80bb7781684': 'Onderaannemer',
      '40174ecb-b8c1-476e-8271-74e654551c29': 'Adviseur',
      '46740616-328f-4588-8145-7798e76c605c': 'Expert',
      '539cd08e-9eb3-4e16-8cc7-8909b7a41573': 'Beheerder'
    }
  }
}
